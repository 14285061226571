.loginEmailVerification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $primary;
  &__logo {
    height: 6.75rem;
    width: 31.8rem;
    margin-bottom: 2.125rem;
  }
  > h1 {
    font-weight: $medium;
    font-size: 1.538rem;
    margin-bottom: 5.625rem;
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    > h2 {
      font-weight: $bold;
      font-size: 1.25rem;
      margin-bottom: 2.37rem;
    }
    &__email {
      font-weight: $medium;
      font-size: 1.25rem;
      margin-bottom: 1.68rem;
      > span {
        font-weight: $bold;
      }
    }
    &__inputContainer {
      margin-bottom: 1.9rem;
      > p {
        font-weight: $medium;
        font-size: 1.1538rem;
        margin: 1vh 0 1vh 0;
        text-align: center;
      }
      &__input {
        background-color: transparent;
        width: 20rem;
        text-align: center;
      }
    }
    &__p {
      font-weight: $medium;
      font-size: 1.25rem;
      margin-bottom: 3.8rem;
      color: $table-grey;
      > span {
        font-weight: $bold;
      }
    }
    &__a {
      text-decoration: none;
      font-weight: $bold;
      color: $table-grey;
    }
    &__button {
      width: 20rem;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 100vh;
    width: 100%;
    &__logo {
      width: 15.8rem;
      height: 3.375rem;
      margin-bottom: 1.125rem;
    }
    > h1 {
      font-weight: $medium;
      font-size: 0.9375rem;
      margin-bottom: 3.8rem;
    }
    &__form {
      width: 100%;
      > h2 {
        font-weight: $bold;
        font-size: 1.5rem;
        margin-bottom: 3vh;
      }
      > button {
        margin-top: 2vh;
        width: 15rem;
      }
      &__email {
        font-weight: $medium;
        font-size: 1rem;
        margin-bottom: 2vh;
        text-align: center;
        > span {
          font-weight: $bold;
        }
      }
      &__inputContainer {
        margin-bottom: 0.9rem;
        > p {
          font-weight: $medium;
          font-size: 0.9rem;
          margin: 2vh 0 0 0;
        }
        &__input {
          width: 15.5rem;
          height: 3.5rem;
        }
      }
      &__p {
        font-weight: $medium;
        font-size: 0.8rem;
        margin-bottom: 3.2rem;
        color: $table-grey;
      }
      &__button {
        width: 19.8rem;
      }
    }
  }
}

.resend {
  color: $blueGreen;
}
