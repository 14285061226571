.mainLayoutContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  &__content {
    transition: all 0.2s ease;
    padding: 2% 3%;
    width: calc(100% - 215px);
    max-width: $xl-max;
    position: absolute;
    top: 4rem;
    margin-left: 215px;
  }
  &__buttonsNav {
    display: none;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    &__content {
      width: 100%;
      padding: 0 0.5rem 5rem 0.5rem;
      margin-left: 0;
      margin-top: 3%;
    }
    &__buttonsNav {
      display: flex;
      margin-top: 2rem;
      .buttonsNav__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.2rem;
        margin: 0 1rem;
        .buttonNav,
        .buttonNav--active {
          cursor: pointer;
          margin-right: 0;
          border: 1px solid $table-grey;
          padding: 0.5rem;
          &__blob,
          &__blob--active {
            display: none;
          }
          &__children,
          &__children--active {
            margin: 0 auto;
            > p {
              font-size: 0.9rem;
              color: $table-grey;
            }
          }
          &:nth-child(n) {
            border-bottom-left-radius: 0.75rem;
            border-top-left-radius: 0.75rem;
            border-bottom-right-radius: 0.75rem;
            border-top-right-radius: 0.75rem;
          }
          &:last-child {
            border-bottom-right-radius: 0.75rem;
          }
        }
        .buttonNav--active {
          background: $blue;
          color: white;
          .buttonNav__children--active p {
            color: $white;
            font-weight: $semi-bold;
          }
        }
      }
    }
  }
}

.mainLayoutContainer--closed {
  @extend .mainLayoutContainer;
  transition: all 0.2s ease;
}

.mainLayoutContainer__header--closed {
  @extend .mainLayoutContainer__header;
  .mainContainer {
    width: 100%;
    padding-left: 80px;
    transition: all 0.2s ease;
    @media screen and (max-width: 1024px) {
      padding-left: 0px;
    }
  }
}

.mainLayoutContainer__content--closed {
  @extend .mainLayoutContainer__content;
  transition: all 0.2s ease;
  margin-left: 100px;
  width: calc(100% - 100px);
  @media screen and (max-width: 1024px) {
    margin-left: 0px;
    width: calc(100%);
  }
}
