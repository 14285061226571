@import '../../themes/patterns';

.buttonJoinMain {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 180px;
  height: 180px;
  border: none;
  border-radius: 20px;
  opacity: 1;
  outline: none;
  box-shadow: 0px 3px 6px #272d3b33;
  position: relative;
  padding: 1.19rem 1.5rem;
  &__iconArrow {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 1rem;
    margin-right: 1rem;
    > svg {
      stroke: $white;
      width: 2rem;
    }
  }
  &__comingSoon {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 1.5rem;
    margin-right: 1rem;
    font-size: 0.8rem !important;
    font-weight: $semi-bold;
    color: $white;
  }
  &__icon {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    margin-left: -0.5rem;
    margin-bottom: 1rem;
    > svg {
      stroke: $white;
      width: 2rem;
    }
  }
  &__loanIcon {
    @extend .buttonJoinMain__icon;
    > svg {
      width: 2.5rem;
    }
  }
  &__children {
    font-weight: bold;
    font-size: 1.25rem;
    color: $white;
    text-align: left;
    text-transform: uppercase;
  }
  &__label {
    font-size: 1.125rem;
    font-weight: $medium;
    align-self: center;
    color: $white;
    opacity: 0.7;
    position: absolute;
    bottom: 0;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 1024px) {
    width: 9.5rem;
    height: 10.5rem;
    padding: 1rem 1.5rem 1rem 0.9rem;
    &__iconArrow {
      width: 2rem;
      height: 2rem;
      margin-bottom: 0.6rem;
      margin-right: 0.9rem;
      > svg {
        width: 1.6rem;
      }
    }
    &__icon {
      width: 1.7rem;
      height: 1.7rem;
      margin-top: 0.8rem;
      margin-left: 0rem;
      margin-bottom: 0.875rem;
      > svg {
        width: 1.7rem;
      }
    }
    &__children {
      font-size: 1rem;
      margin-left: 0rem;
    }
    &__label {
      font-size: 0.8125rem;
      opacity: 0.7;
      margin-bottom: 0.6rem;
    }
  }
}

.color-primary {
  background: $blue 0% 0% no-repeat padding-box;
}
.color-secondary {
  background: $blue 0% 0% no-repeat padding-box;
}
.color-disabled {
  background: $table-grey 0% 0% no-repeat padding-box;
}
.iconColor-primary {
  background-color: $blue-dark;
}
.iconColor-secondary {
  background-color: $blueGreen;
}
