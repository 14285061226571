@import '../../themes/patterns';

.inputHover {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: $primary;
  &:hover {
    .inputHover__hover {
      display: flex;
    }
  }
  &__hover {
    display: none;
    position: absolute;
    top: 3.5rem;
    left: 10rem;
    z-index: 10;
  }
  &__input {
    font-family: $primary;
    font-weight: $regular;
    width: 302px;
    font-size: 18px;
    border: none;
    background-color: transparent;
    padding: 0.25rem 0rem;
    outline: none;
    text-align: left;
    border: none;
    border-bottom: 1px solid $light-grey;
    &:disabled {
      color: rgb(179, 179, 179);
    }
  }
  &__error {
    color: $red2;
    font-weight: $regular;
    font-size: 0.75rem;
    padding: 0.25rem 0rem;
  }
  &__innerError {
    color: $red;
    font-weight: $medium;
    font-size: 0.56rem;
    position: absolute;
    right: 0;
    padding: 0.25rem 0rem;
  }
  &__required {
    color: red;
    position: absolute;
    right: -0.6rem;
    font-size: 1rem;
    font-weight: $medium;
    top: 0;
  }

  @media screen and (max-width: 1024px) {
    &__input {
      font-size: 0.75rem;
    }
    &__hover {
      top: 3rem;
      left: 8rem;
    }
  }
}

.error {
  > input {
    border: 1px solid #e40000;
  }
  > p {
    align-self: flex-start;
    font-weight: $medium;
    font-size: 1rem;
    color: #e40000;
    padding: 0.1rem 0.6rem;
  }
}
