@import '../../themes/patterns';

.iconButton {
  padding: 1.5rem;
  border: 1px solid #bdbdbd;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.45);
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}
