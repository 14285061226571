@import '../../themes/patterns';

.selectToolTip {
  position: relative;
  &:hover {
    .selectToolTip__hover {
      display: flex;
    }
  }
  &__hover {
    display: none;
    position: absolute;
    top: 3.5rem;
    left: 15rem;
    z-index: 10;
  }
  &__select {
    width: 302px;
    font-family: $primary;
    font-weight: $regular;
    color: $title-grey;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid $light-grey;
    background-color: transparent;
    padding: 0.21rem 0rem;
    outline: none;
    text-align: left;
    cursor: pointer;
  }
  &__required {
    color: red;
    position: absolute;
    right: -0.6rem;
    font-size: 1rem;
    font-weight: $medium;
    top: 0;
  }
  @media screen and (max-width: 1024px) {
    &__select {
      font-size: 0.75rem;
    }
    &__hover {
      top: 3rem;
      left: 8rem;
    }
  }
}

.error {
  > select {
    border: 1px solid #e40000;
  }
  > p {
    align-self: flex-start;
    font-weight: $medium;
    font-size: 1rem;
    color: #e40000;
    padding: 0.1rem 0.6rem;
  }
  @media screen and (max-width: 1024px) {
    > p {
      align-self: flex-start;
      font-weight: $medium;
      font-size: 0.55rem;
      color: #e40000;
      padding: 0.1rem 0.6rem;
    }
  }
}
