@import '../../themes/patterns';

.inputFileToolTip {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: $table-grey;
  font-family: $primary;
  width: 302px;
  font-weight: $regular;
  font-size: 1.1rem;
  padding: 0.5rem 0;
  text-align: left;
  border: none;
  border-bottom: 1px solid $light-grey;
  outline: none;
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    cursor: pointer;
  }
  &__card {
    width: 100%;
    margin-bottom: 2.5rem;
    &__download {
      margin-left: 2rem;
      position: absolute;
      top: 15%;
      right: -20%;
    }
    &__error {
      color: $red;
      text-align: left;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
  }
  &__placeholder {
    position: absolute;
    left: 0;
    bottom: 0.5rem;
  }
  cursor: pointer;
  &:hover {
    .inputFileToolTip__hover {
      display: flex;
    }
  }
  &__hover {
    display: none;
    position: absolute;
    top: 4rem;
    left: 2rem;
    z-index: 10;
  }
  &__input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  &__icons,
  &__deleteIcon {
    display: flex;
    align-items: center;
    > svg {
      width: 1.875rem;
      height: 1.875rem;
      margin-right: 0.8rem;
      stroke: $blueGreen-dark;
    }
  }
  &__deleteIcon {
    &__delete {
      position: absolute;
      top: 20%;
    }
    > svg {
      stroke: $red;
    }
  }
  &__required {
    color: $red;
    position: absolute;
    right: -0.5rem;
    font-size: 1rem;
    font-weight: $medium;
    top: 0;
  }
  &__noLoading,
  &__loading,
  &__uploaded {
    background-color: $blue;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0%;
    height: 2px;
  }
  &__loading {
    transition: width 30s;
    width: 90%;
  }
  &__uploaded {
    transition: width 1s;
    width: 100%;
    &__icon {
      svg {
        stroke: $blue;
      }
    }
  }
  &__subtitle {
    color: $table-grey;
    font-size: 15px;
    font-weight: $medium;
    margin-top: 1rem;
    &__error {
      color: $red;
      font-weight: $medium;
    }
  }
  &__requeriments {
    font-size: 0.9rem;
    font-weight: $medium;
    color: #00bdd5;
  }
  &__error {
    border-bottom: 1px solid $red;
    svg {
      stroke: $red;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 2.6rem;
    font-weight: $medium;
    font-size: 0.65rem;
    padding: 0.5rem 0.2rem;
    &__card {
      height: auto;
      &__error {
        font-size: 13px;
      }
    }
    &__icons {
      > svg {
        width: 1.125rem;
        height: 1.125rem;
        margin-right: 0;
      }
    }
    &__deleteIcon {
      > svg {
        margin-left: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    &__hover {
      bottom: 2.6rem;
    }
    &__subtitle {
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.74px;
      color: black;
    }
    &__requeriments {
      margin-top: 0.2rem;
      font-weight: $medium;
      font-size: 0.6875rem;
    }
  }
}

.errorFile {
  > div {
    border: 1px solid #e40000;
  }

  > p {
    align-self: flex-start;
    font-weight: $medium;
    font-size: 1rem;
    color: #e40000;
    padding: 0.1rem 0.6rem;
  }
}
