@import '../../themes/patterns';

.projectDetail {
  width: 100%;
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;
  &--noSales {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
  }
  &One {
    &__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem 0;
      &--projectName {
        display: flex;
        padding-bottom: 0.8rem;
        width: 100%;
        justify-content: space-between;
        > h1 {
          color: $title-grey;
          font-weight: $bold;
          margin-right: 1rem;
          white-space: nowrap;
          font-size: 20px;
        }
      }
      &--icons {
        display: flex;
        align-items: center;
      }
      &--icon {
        position: relative;
        &:hover {
          .projectDetail__tooltip {
            display: block;
          }
        }
        > svg {
          stroke: $title-grey;
          height: 28px;
          width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &--isinNumber {
        display: flex;
        align-items: center;
        color: $title-grey;
        font-weight: $semi-bold;
        font-size: 1.15rem;
        width: 100%;
        max-width: 28rem;
        > p {
          align-self: center;
          margin-right: 0.8rem;
        }
        > span {
          align-self: center;
          font-weight: $regular;
          overflow-wrap: anywhere;
        }
        svg {
          margin-left: 0.5rem;
          min-height: 24px;
          min-width: 24px;
          cursor: pointer;
        }
      }
    }
    &__image {
      img {
        object-fit: cover;
        aspect-ratio: 16/9;
        width: 100%;
      }
      &__description {
        align-items: center;
        background-color: #00000040;
        color: #ffffff;
        display: flex;
        font-size: 0.75rem;
        font-weight: 500;
        height: 1.8rem;
        justify-content: center;
        margin-top: -4px;
        width: 100%;
        input {
          display: none;
        }
        label {
          align-items: center;
          cursor: pointer;
          display: flex;
          font-size: 0.9rem;
          justify-content: center;
          margin: 0px;
          width: 100%;
        }
        svg {
          height: 1.75rem;
          margin-left: 0.2rem;
          stroke: white;
          width: 1.75rem;
        }
      }
    }
    &__viewMore {
      > button {
        width: max-content;
        margin: 3rem 0;
        padding: 1rem 3rem;
      }
    }
    &__infoSection {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin: 1rem 0;
      > h2 {
        margin: 1rem 0rem;
      }
      &--info {
        margin-top: 2rem;
        background: $yellow;
        padding: 1.5rem;
        border-radius: 4px;
        width: 100%;
        color: $black;
      }
      &--awaiting {
        background: $blueGreen !important;
        color: $white !important;
      }
      &--actions {
        margin-top: 2rem;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 2fr));
        gap: 0.5rem;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      &--transfer,
      &--edit,
      &--trash,
      &--withdraw {
        font-size: 0.95rem;
        border: 1px solid #00000040;
        border-radius: 10px;
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: unset;
        font-weight: $semi-bold;
        &:hover {
          background: #00000010;
        }
        > svg {
          margin-left: 0.5rem;
          width: 20px;
          height: 20px;
        }
      }
      &--transfer {
        color: $yellow;
        > svg {
          stroke: $yellow;
        }
      }
      &--trash {
        color: $red;
        > svg {
          stroke: $red;
        }
      }
      &--withdraw {
        color: #dc4f89;
        > svg {
          stroke: #dc4f89;
        }
      }
      > details:first-of-type {
        > summary {
          border-radius: 6px 6px 0px 0px;
        }
      }
      > details:last-of-type {
        > summary {
          border-radius: 0px 0px 6px 6px;
        }
      }
    }
    &__sections {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &__svg {
        cursor: pointer;
      }
      > svg {
        stroke: $blueGreen;
      }
      > h3 {
        font-family: $primary;
        margin-left: 1rem;
        font-weight: $semi-bold;
        color: $blueGreen;
        font-size: 1.15rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    &__td1 {
      display: flex;
      min-width: 250px;
      font-size: 1.1rem;
      font-weight: $semi-bold;
      text-align: left;
      color: $title-grey;
    }
    &__td2 {
      text-align: right;
      color: $table-grey;
      position: relative;
      &__hover {
        position: absolute;
        top: 2.6rem;
        right: -5.3rem;
        display: none;
        z-index: 99;
      }
      &__tooltipContainer {
        margin-left: 6rem;
        width: 20rem;
      }
      &__tooltip {
        padding: 0.5rem;
        font-size: 0.95rem;
      }
    }
    &__description {
      h4 {
        color: #292929;
        font-size: 1.25rem;
        font-family: $primary;
        font-weight: $regular;
        letter-spacing: 1.6;
        padding: 2rem 0 1rem 0;
      }
      p {
        font-family: $secondary;
        font-weight: $regular;
        text-align: left;
        letter-spacing: 176%;
        color: #616d7e;
        font-size: 1.25rem;
      }
    }
    &__mobile {
      margin-top: 2rem;
    }
  }
  &Two {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 2rem;
    &__head {
      display: none;
    }
    &__image {
      display: none;
      width: 100;
      overflow: hidden;
      border-radius: 20px;
      img {
        aspect-ratio: 16/9;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    &__available {
      display: flex;
      flex-direction: column;
      width: 90%;
      min-width: 300px;
      align-items: flex-end;
      &__one {
        width: 100%;
        box-shadow: $paper-shadow;
        border-radius: 10px;
        margin-bottom: 1rem;
      }
      &__overview1 {
        padding: 2rem 2rem 1rem 2rem;
        width: 100%;
        table {
          td {
            font-size: 1rem;
          }
        }
      }
      &__overview2 {
        padding: 2rem;
        width: 100%;
        &__item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 1rem 0;
          h2 {
            text-transform: uppercase;
            font-family: $secondary;
            font-size: 1.2rem;
            font-weight: $medium;
          }
          p {
            color: $grey;
            text-transform: uppercase;
          }
          span {
            font-weight: 600;
            font-size: 1.5rem;
          }
        }
        table {
          td {
            padding: 0.6rem 0 !important;
            font-size: 1rem;
          }
        }
        border-top: 1px solid $light-grey;
      }
      &__invest {
        width: 100%;
      }
      &__percentbar {
        margin-top: 2.1rem;
        margin-bottom: 3rem;
        width: 100%;
        p {
          font-size: 1.5rem;
        }
      }
    }
    &__ended {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      box-shadow: 0px 3px 6px #272d3b33;
      border-radius: 10px;
      margin-bottom: 1rem;
      margin-top: 3rem;
      padding: 2rem 2rem 1rem 2rem;
      text-align: center;
      > svg {
        width: 65px;
        height: 65px;
        stroke: $yellow;
        margin: 0 auto;
        background-color: white;
        border-radius: 100%;
        padding: 2px;
        margin-bottom: 1rem;
        margin-top: -4.7rem;
        border-bottom: 1px solid #272d3b33;
      }
      > h1 {
        margin-bottom: 1rem;
        font-size: 15px;
      }
      > p {
        margin-bottom: 2rem;
      }
      > button {
        width: 100%;
        margin-bottom: 1rem;
        border: 1px solid $yellow;
        font-size: 12px;
        padding: 0;
        > p {
          color: $yellow;
        }
        &:hover {
          padding: 0;
          font-size: 13px;
          background-color: $yellow;
          color: $white;
        }
      }
    }
    &__title {
      font-size: 0.8rem;
      width: 100%;
      color: $title-grey;
      margin-bottom: 1.5rem;
    }
    &__td1,
    &__td2 {
      font-size: 1.05rem;
      p {
        font-weight: $bold;
        color: $title-grey;
        margin: 0.2rem 0;
      }
    }
    &__td1 {
      font-family: $secondary;
      font-weight: $bold;
      text-align: left;
      color: $title-grey;
      &__margin {
        font-weight: $regular;
        margin: 0.5rem 0 0rem 0;
        letter-spacing: 0px;
      }
    }
    &__td2 {
      > p {
        font-size: 1.3rem;
      }
      text-align: right;
      color: $table-grey;
      &__date,
      &__publicDate {
        > p {
          font-size: 1.05rem;
          margin: 0;
          font-weight: $regular;
          color: #6e6e6e;
        }
      }
      &__publicDate {
        > p {
          font-size: 1.25rem;
        }
      }
    }
    &__button,
    &__importButton {
      width: 100%;
      position: relative;
      margin-top: 1rem;
      padding: 2rem;
      > p {
        font-size: 1rem;
      }
    }
    &__importButton {
      padding: 1.5rem;
      margin-top: 2rem;
    }
  }
  &Isin {
    &__input {
      margin-bottom: 0;
      > input {
        font-size: 15px;
        height: auto;
        margin-left: 0.5rem;
      }
    }
    &__spinner {
      height: auto;
      > div {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__tooltip {
    display: none;
    position: absolute;
    top: 3rem;
    left: -5rem;
    text-align: center;
    &__container {
      width: 13rem;
    }
  }
  &__field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.3rem;
    &__title {
      width: 58%;
      font-size: 1.1rem;
      font-weight: 600;
      text-align: left;
      color: #303233;
    }
    &__content {
      width: 42%;
      position: relative;
      overflow: hidden;
      text-align: right;
      font-size: 1.1rem;
      color: #6e6e6e;
      overflow-wrap: anywhere;
      &:hover {
        .projectDetailOne__td2__hover {
          display: flex !important;
        }
      }
    }
  }
  &__description {
    display: flex;
    flex-direction: column;
    width: 100%;
    > p {
      padding-bottom: 0.2rem;
      font-family: $primary;
      letter-spacing: 1.6px;
      min-width: 100px;
      margin-top: 0.3rem;
      font-size: 1.1rem;
      font-weight: 600;
      text-align: left;
      color: #292929;
    }
    > span {
      padding-bottom: 0.5rem;
      font-size: 1.1rem;
      text-align: justify;
      color: #616d7e;
      position: relative;
      font-family: $secondary;
      letter-spacing: 176%;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      line-height: 1.6;
    }
  }
  & table {
    width: 100%;
    margin-top: 0.5rem;
    & tbody {
      & td {
        padding-bottom: 0.2rem;
        font-family: $primary;
        letter-spacing: -0.5px;
        min-width: 100px;
      }
    }
  }
  &__popUp {
    width: 31rem !important;
    height: auto !important;
  }
  &__ndaAgreementContainer {
    width: 65rem;
    height: 40rem;
    padding: 6rem 3rem 1rem 3rem;
  }
  &__ndaAgreement {
    > h1 {
      text-align: left;
    }
    > div {
      margin: 0;
      padding-top: 0rem;
    }
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $title-grey;
      border-radius: 10px;
    }
    &__buttons {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 1.5rem;
      margin-top: 1rem;
      > button {
        min-width: max-content;
        padding: 1rem 3rem;
        margin-right: 0.5rem;
      }
    }
  }
  &__website {
    color: $blue;
    text-decoration: underline;
  }
  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 1024px) {
    width: 90%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 2rem;
    grid-template-areas: 'one' 'two';
    margin: 0 auto;
    &One {
      margin-bottom: 2rem;
      &__td2 {
        &__hover {
          top: 2rem;
          right: -2rem;
        }
        &__tooltipContainer {
          margin-left: -5rem;
          width: 15rem;
        }
        &__tooltip {
          text-align: left;
          margin-left: 0rem;
          font-size: 0.8rem;
          padding: 0.4rem;
        }
      }
      &__viewMore {
        > button {
          width: 100%;
          margin: 3rem 0 0 0;
          padding: 0rem;
        }
      }
      grid-area: one;
      &__image {
        &__description {
          label {
            font-size: 0.75rem;
          }
          svg {
            height: 1.5rem;
            margin-left: 0.15rem;
            width: 1.5rem;
          }
        }
      }
      &__subTitle {
        > h3 {
          color: $title-grey;
          font-size: 0.9rem;
          font-weight: $bold;
          letter-spacing: -0.64px;
        }
      }
      &__infoSection {
        flex-direction: column;
        > h2 {
          margin: 1rem auto;
        }
        &--info {
          font-size: 0.9rem;
          margin-top: 1rem;
        }
        &--transfer,
        &--edit,
        &--trash,
        &--withdraw {
          font-size: 0.75rem;
          margin-right: 1rem;
          > svg {
            margin-left: 0.2rem;
          }
        }
        &--trash {
          margin-right: 0;
        }
      }
      &__header {
        align-items: center;
        &--projectName {
          justify-content: center;
          align-items: center;
          > h1 {
            font-size: 1.16rem;
            letter-spacing: -0.82px;
            color: #464646;
            opacity: 1;
            margin-right: 0rem;
          }
        }
        &--isinNumber {
          font-size: 0.8rem;
          font-weight: 500;
          opacity: 0.45;
          justify-content: center;
          padding: 0;
        }
        &--desktop {
          display: flex;
          font-size: 0.7rem;
          font-weight: $regular;
          color: $title-grey;
          letter-spacing: -0.73px;
          &__h1 {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.625rem;
            > h1 {
              font-size: 1.16rem;
              letter-spacing: -0.82px;
              color: $title-grey;
              opacity: 1;
            }
            &__icons {
              display: flex;
              justify-content: center;
              align-items: center;
              > a {
                > svg {
                  stroke: $title-grey;
                  height: 1.4rem;
                }
              }
              > svg {
                stroke: $title-grey;
                height: 1.2rem;
              }
            }
          }
        }
      }
      &__sections {
        > h2 {
          margin-top: 0;
        }
        > h3 {
          font-size: 0.857rem;
          font-weight: $bold;
          letter-spacing: -0.64px;
        }
      }
    }
    &Two {
      grid-area: two;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0;
      &__head {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.8rem;
        margin-top: 3rem;
        &__isinNumber {
          display: flex;
          align-items: center;
          font-size: 0.8rem;
          font-weight: $medium;
          opacity: 0.45;
          svg {
            margin-left: 0.3rem;
            cursor: pointer;
          }
          input {
            margin-left: 0.5rem;
          }
        }
        &__h1 {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0.625rem;
          > h1 {
            font-size: 1.16rem;
            letter-spacing: -0.82px;
            color: $title-grey;
            opacity: 1;
          }
          &__icons {
            display: flex;
            justify-content: center;
            align-items: center;
            > a {
              > svg {
                stroke: $title-grey;
                height: 1.4rem;
              }
            }
            > svg {
              stroke: $title-grey;
              height: 1.2rem;
            }
          }
        }
      }
      &__image {
        display: flex;
        flex-direction: column;
        margin-bottom: 2.5rem;
        width: 100%;
        img {
          object-position: center;
        }
      }
      &__available {
        margin-top: 0rem;
        margin-bottom: 2rem;
        width: 100%;
        align-items: flex-end;
        > p {
          font-size: 0.6rem;
        }
        &__percentbar {
          width: 100%;
          p {
            font-size: 0.7rem;
          }
        }
      }
      &__ended {
        > svg {
          margin-top: -4.2rem;
        }
        > button {
          width: 90%;
          padding: 5px;
          &:hover {
            padding: 5px;
          }
        }
      }
      &__td2 {
        &__date,
        &__publicDate {
          > p {
            letter-spacing: -0.45px;
            font-size: 0.75rem;
            font-weight: 500;
          }
        }
      }
      &__button,
      &__importButton {
        padding: 0.5rem;
        height: 3rem;
        > p {
          font-size: 0.8rem;
        }
      }
    }
    & table {
      margin-top: 0.25rem;
      & tbody {
        & td {
          padding: 0.2rem 0;
          font-family: $primary;
          letter-spacing: -0.45px;
          font-size: 0.857rem;
          font-weight: $medium;
        }
      }
    }
    &__isin {
      margin: 0 auto;
      > p {
        margin-right: 0.5rem;
      }
      > span {
        width: 90%;
      }
    }
    &__field {
      &__title {
        font-size: 0.75rem;
      }
      &__content {
        font-size: 0.75rem;
        max-width: 100%;
      }
    }
    &__description {
      width: 100%;
      > p,
      span {
        font-family: 'Montserrat';
        font-size: 0.857rem;
      }
      > p {
        padding-bottom: 0rem;
      }
      > span {
        padding: 0.1rem 0 0.5rem 0;
      }
    }
    &__three {
      grid-area: three;
      &__title {
        h2 {
          margin-top: 1rem;
        }
        > h3 {
          color: $title-grey;
          font-size: 0.9rem;
          font-weight: $bold;
          letter-spacing: -0.64px;
        }
      }
      &__description {
        margin-top: 1rem;
        h4 {
          font-size: 0.8rem;
          padding: 0.2rem 0;
          letter-spacing: -0.45px;
        }
        p {
          font-weight: $regular;
          font-size: 0.7rem;
          letter-spacing: -0.5px;
        }
      }
    }
    &__four {
      grid-area: four;

      &__title {
        > h3 {
          color: $title-grey;
          font-size: 0.9rem;
          font-weight: $bold;
          letter-spacing: -0.64px;
        }
        > svg {
          margin-left: 0.5rem;
          height: 1rem;
        }
      }
      &__button {
        width: 110%;
      }
      &__button,
      &__button:disabled {
        font-size: 1rem;
        margin: auto;
        margin-top: 5rem;
        > p {
          font-size: 0.75rem;
        }
        &:hover {
          > label {
            top: -2rem;
            bottom: 2.2rem;
            font-size: 0.8rem;
          }
        }
      }
    }
    &__popUp {
      width: 90% !important;
      height: auto !important;
    }
    &__ndaAgreementContainer {
      width: 90%;
      height: 95%;
      padding: 6rem 2rem 0rem 2rem;
    }
    &__ndaAgreement {
      &__buttons {
        flex-direction: column;
        margin-bottom: 1rem;
        > button {
          min-width: 100%;
          padding: 1rem;
          margin-top: 0.75rem;
        }
      }
    }
  }
}
