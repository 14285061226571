.forgotPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $primary;
  &__logo {
    height: 6.75rem;
    width: 31.8rem;
    margin-bottom: 2.125rem;
  }
  > h1 {
    font-weight: $medium;
    font-size: 1.538rem;
    margin-bottom: 4rem;
  }
  &__p {
    margin-top: 0.5rem;
    font-weight: $medium;
    color: $table-grey;
    font-size: 1.25rem;
    margin-bottom: 2.5rem;
    &__link {
      text-decoration: none;
      font-weight: $bold;
      color: $blueGreen;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    > h2 {
      font-weight: $bold;
      font-size: 1.25rem;
      margin-bottom: 2rem;
    }
    > p {
      font-size: 13px;
      font-weight: $regular;
      text-align: center;
    }
    &__input {
      background-color: transparent;
      margin-top: 2rem;
      text-align: center;
    }
    &__inputMain {
      margin-bottom: 5rem;
    }
    &__button {
      width: 20rem;
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 100vh;
    &__logo {
      width: 15.8rem;
      height: 3.375rem;
      margin-bottom: 1.125rem;
    }

    > h1 {
      font-weight: $medium;
      font-size: 0.9375rem;
      margin-bottom: 3.8rem;
    }
    &__p {
      margin-top: 0.937rem;
      font-size: 0.75rem;
      margin-bottom: 2.2rem;
    }
    &__form {
      width: 85%;
      display: flex;
      flex-direction: column;
      align-items: center;
      > h2 {
        font-size: 1.5rem;
        font-weight: $semi-bold;
        margin-bottom: 2rem;
      }
      > p {
        text-align: center;
        padding: 1rem;
      }
      &__input {
        width: 20rem;
        height: 3rem;
        text-align: center;
      }
      &__inputMain {
        margin-bottom: 4rem;
      }
      &__button {
        width: 20rem;
      }
    }
  }
}
