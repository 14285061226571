@import '../../themes/patterns';

.sectionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  // height: 4.1rem;
  > * {
    padding: 1rem;
  }
  summary::-webkit-details-marker {
    display: none;
  }
  &__title {
    align-items: center;
    background: #ffffff;
    border: $paper-border;
    border-radius: $paper-radius;
    box-shadow: $paper-shadow;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  &__actions {
    display: flex;
    margin-right: 0.5rem;
    svg {
      color: #1c7391;
      border-radius: 100%;
      cursor: pointer;
      height: 1.75rem;
      margin-left: 1rem;
      width: 1.75rem;
    }
  }
  &__arrow {
    transform: rotate(0);
    transition: 0.25s ease-in;
    &--active {
      transform: rotate(90deg);
      transition: 0.25s ease-out;
    }
  }
  @media screen and (max-width: 1024px) {
    &__actions {
      display: flex;
    }
  }
}

.sectionContainer[open] {
  > summary {
    border-radius: $paper-radius;
  }
}

.sectionContainer[open] ~ .sectionContainer:first-of-type > summary {
  border-radius: $paper-radius;
}
