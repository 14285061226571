@import '../../themes/patterns';

.transactions {
  &__container {
    border: 1px solid rgba(grey, 0.3) !important;
    box-shadow: 0px 3px 6px #272d3b33 !important;
    font-family: $primary !important;
    font-size: 0.929rem !important;
    border-radius: 10px !important;
    padding: 1.429rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    @include xs {
      flex-direction: column;
    }
    @include xxs {
      flex-direction: column;
    }
  }
  &__canceled {
    text-decoration: line-through;
    color: $table-grey;
  }
  &__title {
    margin-bottom: 1rem;
    font-weight: $bold;
    font-size: 2.143rem;
    line-height: 2.643rem;
    color: #1c7391;
  }
  &__text {
    margin-bottom: 1rem;
    font-weight: $medium;
    font-size: 1rem;
    line-height: 1.214rem;
    letter-spacing: 0.01em;
    color: $black;
  }
  &__inputContainer {
    display: flex;
    align-items: center;
  }
  &__checkedIcon {
    padding: 0.1rem;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.45);
    border-radius: 50%;
    background-color: $blue;
    stroke: white;
    cursor: pointer;
  }
  &__pencilIcon {
    cursor: pointer;
  }
  &__input {
    font-family: 'Montserrat';
    font-weight: $medium;
    font-size: 0.929rem;
    line-height: 1.328rem;
    color: rgb(110, 110, 110);
    width: 225px;
    &:focus {
      border-bottom: 1px solid #e6e6e6 !important;
    }
    cursor: default;
  }
  &__crypto {
    font-family: 'Montserrat';
    font-weight: $medium;
    font-size: 0.929rem;
    line-height: 1.328rem;
    color: rgb(110, 110, 110);
    width: 170px;
    text-align: center;
  }
  &__editInput {
    font-family: 'Montserrat';
    font-weight: $medium;
    font-size: 0.929rem;
    line-height: 1.328rem;
    color: rgb(110, 110, 110);
    width: 225px;
  }
  &__status {
    font-weight: $semi-bold !important;
    color: rgb(80, 80, 80) !important;
  }
  &__content {
    &__spinner {
      height: 100%;
      justify-content: center;
    }
    &__grid {
      &__tableContainer {
        min-height: 30rem;
        height: 55vh;
        margin-bottom: 2rem;
        .MuiDataGrid-root {
          border: 1px solid rgba(grey, 0.3) !important;
          box-shadow: 0px 3px 6px #272d3b33 !important;
          font-family: $primary !important;
          font-size: 13px !important;
          border-radius: 10px !important;
          min-height: 322px !important;
          max-width: $xl-max !important;
        }
        .MuiDataGrid-renderingZone {
          width: 100% !important;
        }

        .MuiDataGrid-root .MuiDataGrid-window::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        .MuiDataGrid-root .MuiDataGrid-window::-webkit-scrollbar-thumb {
          background-color: grey;
          border-radius: 10px;
        }

        .MuiDataGrid-root .MuiDataGrid-footer {
          justify-content: center !important;
        }

        .MuiDataGrid-root .MuiDataGrid-iconSeparator {
          display: none !important;
        }

        .MuiDataGrid-root .MuiDataGrid-viewport,
        .MuiDataGrid-root
          .MuiDataGrid-cell--textCenter.MuiDataGrid-cell--withRenderer,
        .MuiDataGrid-root
          .MuiDataGrid-cell--textCenter.MuiDataGrid-cell--editing {
          overflow: visible !important;
        }

        .MuiDataGrid-root .MuiDataGrid-columnHeader,
        .MuiDataGrid-root .MuiDataGrid-cell {
          padding: 0 10px !important;
        }

        .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
          min-width: 100% !important;
          width: fit-content !important;
        }

        .MuiDataGrid-root .MuiDataGrid-columnHeader {
          display: flex !important;
          position: relative !important;
          align-items: center !important;
          flex-grow: 1;
        }

        .MuiDataGrid-root .MuiDataGrid-columnHeaderWrapper {
          justify-content: space-between !important;
        }

        .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
          color: #6e6e6e;
        }

        .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
          padding: 0px !important;
        }

        .MuiDataGrid-root .MuiDataGrid-cell,
        .MuiDataGrid-root .MuiDataGrid-cellCenter {
          overflow: hidden !important;
          white-space: nowrap !important;
          border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
          text-overflow: ellipsis !important;
          color: #6e6e6e;
          font-weight: 500;
          outline: none !important;
        }

        .MuiDataGrid-columnHeader .MuiDataGrid-columnHeader--sortable {
          flex-grow: 1;
          max-width: none;
          width: unset;
        }

        .MuiDataGrid-root .MuiDataGrid-cellCenter {
          overflow: visible !important;
        }

        .lastColumn {
          position: relative;
        }

        .lastColumn + .MuiDataGrid-cell,
        .lastHeader + .MuiDataGrid-cell {
          display: none !important;
        }

        .MuiDataGrid-root .MuiDataGrid-window {
          overflow-x: auto !important;
        }

        .MuiDataGrid-root .MuiDataGrid-row {
          position: relative;
          overflow-y: visible !important;
          overflow-x: visible !important;
          margin: 0;
          padding: 0;
          width: 100% !important;
          justify-content: space-between !important;
        }
      }
    }
  }
}
