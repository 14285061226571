@import '../../themes/patterns';

.sideDrawer {
  display: none;
  background: $blue;
  opacity: 0.95;
  position: fixed;
  width: 100%;
  z-index: 100;
  height: 85vh;
  transform: translateY(-85vh);
  transition: transform 0.3s ease-out;
  &--open {
    @extend .sideDrawer;
    transform: translateY(52px);
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
