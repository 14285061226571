.signUpLayoutContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__content {
    padding: 4rem 5rem;
    width: 100%;
    height: 100%;
  }
  &__footer {
    display: flex;
    align-items: center;
    height: 5rem;
    width: 100%;
    background-color: #00bdd51a;
    > span {
      margin-left: 1.75rem;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    &__footer {
      display: none;
    }
    &__content {
      width: 100%;
      height: 100%;
      padding: 3rem 0rem;
    }
  }
}
