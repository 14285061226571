.loginSetPasswordMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $primary;
  &__logo {
    height: 6.75rem;
    width: 31.8rem;
    margin-bottom: 2.125rem;
  }
  > h1 {
    font-weight: $medium;
    font-size: 1.538rem;
    margin-bottom: 5.625rem;
  }
  &__form {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__inputContainer {
      display: flex;
      flex-direction: column;
      > h2 {
        font-weight: $bold;
        font-size: 1.25rem;
        margin-bottom: 2.37rem;
        text-align: center;
      }
      &__input {
        background: transparent;
        width: 21.12rem;
        margin: 0 auto;
        height: 3.5rem;
        text-align: center;
      }
      &__inputMain {
        margin-bottom: 1.17rem;
      }
    }
  }
  &__button {
    margin: 0 auto;
    margin-top: 2rem;
  }
  &__buttonUpdatePassword {
    width: 15rem;
    margin: 0 auto;
    margin-top: 4.25rem;
  }

  &__mustContain {
    text-align: center;
    margin-top: 1.5rem;
    > p {
      font-family: $primary;
      font-weight: $medium;
      line-height: 30px;
      font-size: 1.25rem;
    }
    &__validation {
      display: flex;
      > p {
        font-weight: $medium;
        font-size: 1.25rem;
        margin-left: 1rem;
      }
      &__check {
        font-weight: $medium;
        font-size: 1.25rem;
        color: $green;
      }
      &__error {
        font-weight: $medium;
        font-size: 1.25rem;
        color: $red;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 100vh;
    &__logo {
      width: 15.8rem;
      height: 3.375rem;
      margin-bottom: 1.125rem;
    }
    > h1 {
      font-weight: $medium;
      font-size: 0.9375rem;
      margin-bottom: 3.8rem;
    }
    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      &__inputContainer {
        display: flex;
        flex-direction: column;
        > h2 {
          font-weight: $blue;
          font-size: 1.5rem;
          margin-bottom: 0.8rem;
        }
        &__input {
          width: 20rem;
        }
        &__inputMain {
          margin-bottom: 1.25rem;
        }
      }
    }
    &__button {
      margin-top: 0.75rem;
    }
    &__mustContain {
      position: relative;
      right: 0;
      margin-left: 0rem;
      margin-top: 2rem;
      > p {
        font-size: 0.62rem;
        line-height: 20px;
      }
      &__validation {
        display: flex;
        > p {
          font-size: 0.62rem;
          margin-left: 0.5rem;
        }
        &__check {
          font-size: 0.62rem;
        }
        &__error {
          font-size: 0.62rem;
        }
      }
    }
  }
}
