@import '../../themes/patterns';

.accountCreatedMain {
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-family: $primary;
  margin-left: 5rem;
  > h1 {
    color: $title-grey;
    font-size: 1.5rem;
  }
  &__text {
    color: $table-grey;
    margin-top: 3rem;
    font-size: 1.25rem;
  }
  &__link {
    cursor: pointer;
    margin-top: 3rem;
    color: $blueGreen;
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: $bold;
  }

  @media screen and (max-width: 1024px) {
    justify-content: center;
    margin-left: 1.813rem;
    margin-right: 1.813rem;

    > h1 {
      font-size: 1.125rem;
      text-align: center;
    }
    &__text {
      text-align: center;
      color: $table-grey;
      margin-top: 3rem;
      font-size: 0.813rem;
    }
    &__link {
      text-align: center;
      margin-top: 1.875rem;
      color: $blueGreen;
      text-decoration: none;
      font-size: 0.938rem;
      font-weight: $bold;
    }
  }
}
