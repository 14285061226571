@import '../../themes/patterns';

.updatePassword {
  margin: 3rem 0 0 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  row-gap: 0.625rem;
  column-gap: 0.625rem;
  margin-bottom: 1rem;
  > h1 {
    grid-column: 1/3;
    font-weight: $bold;
    color: $title-grey;
    letter-spacing: 0px;
  }
  &__popup {
    height: 15rem;
  }
  &__container {
    grid-column: 1/3;
    color: $table-grey;
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 40.62rem;
    > a {
      color: $blue;
      text-decoration: none;
      font-size: 1.05rem;
      font-weight: $medium;
    }
  }
  &__input {
    grid-column: 1/3;
    font-family: $primary;
    text-align: left;
    font-weight: $medium;
    letter-spacing: 0px;
    color: $table-grey;
    margin-bottom: 0px;
  }
  ::placeholder {
    font-family: $primary;
    color: $table-grey;
    font-size: 1rem;
  }
  &__mustContain {
    margin-top: 1rem;
    margin-bottom: 3rem;
    grid-column: 1/3;
    > p {
      font-family: $primary;
      font-weight: $medium;
      line-height: 30px;
      font-size: 1.25rem;
    }
    &__validation {
      display: flex;
      > p {
        font-weight: $medium;
        font-size: 1.25rem;
        margin-left: 1rem;
      }
      &__check {
        font-weight: $medium;
        font-size: 1.25rem;
        color: $green;
      }
      &__error {
        font-weight: $medium;
        font-size: 1.25rem;
        color: $red;
      }
    }
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    button {
      margin-top: 1.5rem;
      margin-left: 0rem;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    > h1 {
      grid-column: 1/3;
      font-size: 1.125rem;
      color: $title-grey;
      letter-spacing: 0px;
      letter-spacing: -0.82px;
      text-align: center;
      margin: 0;
      margin-top: 1rem;
      margin-bottom: 2.05rem;
    }
    &__container {
      margin-bottom: 0.45rem;
      width: 100%;
      > a {
        font-size: 0.62rem;
        font-weight: $medium;
      }
    }
    &__input {
      margin-bottom: 0rem;
    }
    &__mustContain {
      grid-column: 1/3;
      margin-top: 1.32rem;
      > p {
        font-size: 0.62rem;
        line-height: 20px;
      }
      &__validation {
        display: flex;
        margin-top: 0.5rem;
        > p {
          font-size: 0.62rem;
          margin-left: 0.5rem;
        }
        &__check {
          font-size: 0.62rem;
        }
        &__error {
          font-size: 0.62rem;
        }
      }
    }
    &__buttons {
      flex-direction: column;
      button {
        width: 18rem;
      }
    }
    ::placeholder {
      font-family: $primary;
      color: $table-grey;
      font-size: 0.7rem;
    }
  }
}
