@import '../../themes/patterns';

.chip {
  &__container {
    min-width: 5rem;
    width: fit-content;
    min-height: 1.92rem;
    font-family: $primary;
    font-weight: 600;
    font-size: 0.75rem;
    color: $white;
    box-shadow: $chip-shadow;
    padding: 0.5rem 1rem;
    border-radius: $chip-radius;
    text-align: center;
    &--default {
      background-color: $blue;
    }
    &--success {
      background-color: $green;
    }
    &--error {
      background-color: $red;
    }
    &--warning {
      background-color: $yellow;
    }
    &--disabled {
      background-color: $table-grey !important;
    }
  }
}
