@import '../../themes/patterns';

.stepButton {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  width: 160px;
  height: 42px;
  border: 1px solid $blue-dark;
  cursor: pointer;
  color: $blue-dark;
  background: $white;
  border-radius: 10px;
  font-family: $secondary;
  font-size: 1.1rem;
  font-weight: $semi-bold;
  letter-spacing: 0px;
  margin: 0 1rem 2rem 0;
  padding: 0;
  &:hover {
    color: $white;
    background: $blue-dark;
  }
  &:disabled {
    background: $light-grey !important;
    color: $table-grey !important;
    border: 1px solid $table-grey;
  }
  &--mobile {
    display: none;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 3.5rem;
    font-size: 0.875rem;
    margin-left: 0;
    margin-right: 0;
    &--desktop {
      display: none;
    }
    &--mobile {
      display: flex;
    }
  }
}

.makeStyles-root-4 {
  @media screen and (max-width: 650px) {
    width: auto !important;
    height: auto !important;
    padding: 4px !important;
  }
}

.WithStyles\(ForwardRef\(StepConnector\)\)-line-10 {
  @media screen and (max-width: 650px) {
    display: none !important;
  }
}

.MuiStepper-root {
  padding: 0px !important;
  @media screen and (max-width: 1024px) {
    padding: 0px !important;
  }
}

.stepperCircleMain {
  width: 2.75rem;
  display: flex;
  justify-content: space-between;
  &__circle {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: $white;
  }
}

.colorStep {
  background-color: $blue;
}

.nextBackButtons {
  display: flex;
  margin: 3rem 0;
  &__alignment {
    display: flex;
    flex-direction: row;
  }

  @media screen and (max-width: 1024px) {
    margin: 0 auto;
    margin-top: 3rem;
    width: 100%;
    flex-wrap: wrap;
    &__alignment {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
    }
  }
}
