.loginLayoutContainer {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  &__content {
    display: grid;
    width: 100%;
    height: 100vh;
    justify-content: center;
    grid-template-columns: 58% 42%;
  }
  &__footer {
    display: flex;
    align-items: center;
    height: 5rem;
    width: 100%;
    background-color: #00bdd51a;

    > img {
      margin-left: 1.75rem;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    &__footer {
      display: none;
    }
    &__content {
      grid-template-columns: 1fr;
      width: 100%;
      height: 100%;
    }
  }
}
