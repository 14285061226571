@import '../../themes/patterns';

.inputMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: $primary;
  &__input {
    letter-spacing: -0.5px;
    width: 300px;
    font-family: $primary;
    height: 30px;
    font-weight: $regular;
    font-size: 18px;
    padding: 0.25rem 0rem;
    outline: none;
    text-align: left;
    border: none;
    border-bottom: 1px solid $light-grey;
    background: transparent;
    transform-origin: left;
    transition: border 300ms ease-in;
    &:focus {
      border-bottom: 2px solid #178097;
    }
  }
  &__error {
    color: $red2;
    font-weight: $regular;
    padding: 0.25rem 0rem;
    text-align: center;
    width: 100%;
    &--left {
      @extend .inputMain__error;
      text-align: left;
    }
  }
  &__innerError {
    color: $red;
    font-weight: $medium;
    font-size: 0.56rem;
    position: absolute;
    right: 0;
    padding: 0.25rem 0rem;
  }
  &__required {
    color: red;
    position: absolute;
    right: -0.6rem;
    font-size: 1rem;
    font-weight: $medium;
    top: 0;
  }

  @media screen and (max-width: 1024px) {
    &__input {
      font-size: 13px;
      width: 200px;
    }
    &__error {
      font-size: 13px;
    }
  }
}

input.PhoneInputInput {
  font-family: 'Montserrat';
  font-weight: 400;
  width: 269.52px;
  font-size: 18px;
  background-color: transparent;
  padding: 0.25rem 0rem;
  outline: none;
  text-align: left;
  border: none;
  border-bottom: 1px solid #e6e6e6;
  @media screen and (max-width: 1024px) {
    font-size: 0.75rem;
    width: 262px;
  }
}
.PhoneInput {
  max-width: 302px;
}

input:-internal-autofill-selected {
  font-size: 18px;
}
