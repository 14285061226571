@import '../../themes/_patterns.scss';
@import '../../themes/mixins';

.paper-title {
  display: grid;
  grid-template-rows: 1fr;
  box-shadow: $paper-shadow;
  border: $paper-border;
  border-radius: $paper-radius;
  padding: 1rem;
  @include xs {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr;
    gap: 1rem;
  }
  @include sm {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 1rem;
  }
  &--1col {
    grid-template-columns: 1fr;
  }
  &--2col {
    grid-template-columns: 3fr 1fr;
  }
  > div > h1 {
    font-family: $secondary;
    font-size: 3rem;
    background: linear-gradient(270deg, #00bdd5 0%, #00ccf2 25%, #1c7391 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0.15em;
    @include xs {
      font-size: $text-2xl !important;
    }
    @include sm {
      font-size: 2rem !important;
    }
    @include md {
      font-size: 2rem !important;
    }
  }
  &__left-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  &__subtitle {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    font-size: 1.15em;
    font-family: $primary;
    font-weight: $medium;
    > p {
      @include xs {
        font-size: 0.8em !important;
      }
      @include sm {
        font-size: 0.9em !important;
      }
    }
    &--notice {
      font-size: 0.9em;
      font-style: italic;
      color: $grey;
      opacity: 0.75;
    }
  }
  &__actions-container {
    justify-self: flex-end;
    @include xs {
      width: 100%;
    }
    @include sm {
      width: 100%;
    }
  }
}
