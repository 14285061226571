@import '../../themes/patterns';

.selectMain {
  width: 302px;
  position: relative;
  display: flex;
  flex-direction: column;
  &__select {
    padding: 0.25rem 0rem;
    letter-spacing: -0.5px;
    width: 90%;
    font-size: 18px;
    display: inline-block;
    cursor: pointer;
    height: 31px;
    outline: 0;
    border: 0;
    border-bottom: 1px solid $light-grey;
    position: relative;
    &::-ms-expand {
      display: none;
    }
  }
  &__error {
    color: $red2;
    font-weight: $regular;
    font-size: 0.75rem;
    padding: 0.5rem;
  }
  &__innerError {
    color: $red;
    font-weight: $medium;
    font-size: 0.56rem;
    position: absolute;
    right: 0;
    padding: 0.5rem;
  }
  &__required {
    color: red;
    position: absolute;
    right: -0.6rem;
    font-size: 1rem;
    font-weight: $medium;
    top: 0;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    display: block;
    margin-left: 0;
    font-size: 0.8rem !important;
    &__select {
      font-size: 0.8rem !important;
    }
  }
}
