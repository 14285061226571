@import '../../themes/_patterns.scss';

.projectTabs {
  display: flex;
  @media screen and (max-width: 600px) {
    justify-content: center;
  }
}

.menuTab {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $title-grey;
  cursor: pointer;
  display: inline-flex;
  font-family: $primary;
  font-weight: $medium;
  padding: 1rem 1.5rem;
  padding-top: 0rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  outline: 0;
  width: max-content;
  &:last-child {
    margin-right: 0;
  }
  &--active {
    @extend .menuTab;
    color: $title-grey;
    font-weight: $bold;
    border-bottom: 4px solid $blue;
  }
}
