@import '../../themes/patterns';
@import '../../themes/mixins';

.mainButton {
  border: none;
  background: $blue;
  border-radius: 10px;
  @include buttonStandardStyles;
  transition: background-color ease-in-out 300ms;
  > p {
    color: $white;
  }
  &:hover {
    background: #1a728b;
  }
  &:disabled {
    background: $table-grey;
  }
}

.button {
  background: transparent;
  border: 1px solid $blue;
  border-radius: 10px;
  @include buttonStandardStyles;
  transition: background-color ease-in-out 300ms;
  > p {
    color: $blue;
  }
  &:hover {
    border: 1px solid rgba(0, 189, 213, 0.8);
    border-radius: 10px;
    p {
      opacity: 80%;
      > svg {
        stroke: white;
      }
    }
  }
  &:active {
    border: 1px solid $blue;
    p {
      opacity: 100%;
    }
  }

  &:disabled {
    border: 1px solid $table-grey;
    > p {
      color: $table-grey;
      opacity: 100%;
    }
    > label {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    &:disabled {
      > label {
        display: none;
      }
      &:hover {
        > label {
          max-width: 15rem;
          bottom: 5rem;
          padding: 0.7rem;
        }
      }
    }
  }
}

.buttonGradient {
  border: none;
  background: transparent linear-gradient(270deg, $blue 0%, $blueGreen 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  @include buttonStandardStyles;
  > p {
    color: $white;
  }
  &:hover {
    background: $blue;
  }
  &:active {
    background: $blueGreen;
  }
  &:disabled {
    background: $table-grey;
  }

  @media screen and (max-width: 1024px) {
    &:disabled {
      > label {
        display: none;
      }
      &:hover {
        > label {
          max-width: 15rem;
          bottom: 5rem;
          padding: 0.7rem;
        }
      }
    }
  }
}

.button2 {
  border: none;
  background: transparent;
  transition: background-color ease-in-out 300ms;
  @include buttonStandardStyles;
  > p {
    color: $blue;
  }
  &:hover {
    p {
      opacity: 80%;
    }
  }
  &:active {
    p {
      opacity: 100%;
    }
  }
  &:disabled {
    p {
      opacity: 100%;
      color: $table-grey;
    }
  }
}
