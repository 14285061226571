@import '../../themes/patterns';

.home {
  width: 100%;
  height: 100%;
  margin-top: 3rem;
  &__header {
    font-family: $primary;
    margin: 3rem 0;
    display: grid;
    grid-template-columns: 4fr 1fr;
    > h1 {
      color: $title-grey;
    }
    > a {
      color: $blue;
      text-decoration: none;
      font-size: 1.25rem;
      font-weight: $medium;
      justify-self: flex-end;
    }
  }
  &__cardProjects {
    width: 100%;
    &__scroll {
      display: none;
    }
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 3rem;
      grid-row-gap: 1rem;
      margin-bottom: 2rem;
    }
  }
  &__littleCardsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }
  &__statistics {
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 0px 0px 10px $shadow;
    border-radius: 25px;
    margin: 5rem 0;
    background: $light-grey;
    > h1 {
      margin: 4rem 0 1rem 0;
      color: $title-grey;
    }
    &__row {
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: center;
      border-bottom: 1px solid $light-grey;
      &:last-child {
        border-bottom: none;
      }
    }
    &__item {
      width: minmax(10rem, 15rem);
      padding: 2rem 3rem;
      > svg {
        stroke: $title-grey;
        width: 44px;
        height: 44px;
      }
      > p {
        color: $blue;
        font-size: 1.5rem;
        font-weight: $medium;
      }
      > span {
        color: $title-grey;
        font-weight: $semi-bold;
      }
      &__loan {
        @extend .home__statistics__item;
        margin-top: -6px;
        > svg {
          height: 50px;
          width: 50px;
        }
      }
      &:hover {
        > span {
          color: $blue;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &__header {
      grid-template-columns: 100%;
      grid-template-areas: 'gridA' 'gridH1';

      > h1 {
        grid-area: gridH1;
        font-size: 1.125rem;
        text-align: center;
        margin-top: 1rem;
      }
      > a {
        text-align: right;
        grid-area: gridA;
        font-size: 0.87rem;
        margin-top: -1.6rem;
      }
    }
    &__cardProjects {
      display: none;
      &__scroll {
        display: flex;
        width: 100%;
        height: 22rem;
        margin-top: -2rem;
        & div.cardProjects {
          margin: 0rem;
          margin-right: 1rem;
        }
      }
      &__container {
        grid-template-columns: 1fr;
        grid-row-gap: 0.5rem;
      }
    }
    &__littleCardsContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 0 1rem;
    }
    &__statistics {
      margin-bottom: 1rem;
      > h1 {
        font-size: 1.125rem;
      }
      &__row {
        flex-direction: column;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
