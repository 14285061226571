@import '../../themes/patterns';

.landingPage {
  &__container {
    min-width: 100vw;
    min-height: 100vh;
    background-color: #f5fbff;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 60% 30%;
    gap: 0.5rem;
    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-template-rows: 55% 25% 20%;
    }
    @media screen and (max-width: 440px) {
      grid-template-columns: 1fr;
      grid-template-rows: 40% 30% 30% !important;
    }
  }

  &__containerCards {
    min-width: 100vw;
    padding: 4rem 0rem;
    h4 {
      font-family: Poppins;
      margin-top: 2rem;
      font-size: 2.9991rem;
      text-align: center;
    }
    @media screen and (max-width: 600px) {
      h4 {
        font-size: 24px;
      }
    }
  }

  &__containerContactAndRoad {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(245, 251, 255);
  }

  &__figure {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-row: 1 / 2;
    margin: 1rem 3rem 0rem;
    @media screen and (max-width: 600px) {
      grid-row: span 1;
    }
  }

  &__content {
    grid-row: 1 / 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 5%;
    h2 {
      font-family: $secondary;
      font-weight: 700;
      font-size: 3.5rem;
      line-height: 3.8rem;
      background: linear-gradient(
        270deg,
        #00bdd5 0%,
        #00ccf2 25%,
        #1c7391 100%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      font-family: $primary;
      font-size: 1.5rem;
    }
    @media screen and (max-width: 900px) {
      grid-row: 2 / 3;
      padding: 2%;
      h2 {
        font-size: 2.67rem;
        line-height: unset;
        text-align: center;
        padding-inline: 1.5rem;
      }
      p {
        font-size: 1.35rem;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        padding-inline: 1.5rem;
      }
    }
    @media screen and (max-width: 600px) {
      p {
        text-align: justify;
      }
    }
  }

  &__contentCards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    padding: 0 3rem;
    @media screen and (max-width: 1040px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 600px) {
      justify-items: center;
      grid-template-columns: 1fr;
    }
    h3 {
      font-size: 1rem;
    }
  }

  &__contentContactAndRoad {
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    h2 {
      text-align: center;
      font-family: Raleway, sans-serif;
      font-weight: 700;
      line-height: 96px;
      font-size: 80px;
      margin: 0px;
      padding: 32px;
      background: linear-gradient(
        270deg,
        #00bdd5 0%,
        #00ccf2 25%,
        #1c7391 100%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    h5 {
      margin: 0px 0px 9.8px;
      text-align: center;
      font-family: Montserrat, sans-serif;
      font-weight: 500;
      padding: 0 32px;
      line-height: 44.8px;
      font-size: 28px;
    }
    @media screen and (max-width: 900px) {
      h2 {
        font-size: 2.67rem;
        line-height: unset;
        text-align: center;
        padding-inline: 1.5rem;
      }
      h5 {
        font-size: 1.35rem;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        padding-inline: 0.5rem;
        text-align: justify;
      }
    }
  }

  &__buttonContactUs {
    margin-top: 10px;
    padding: 1rem 4rem;
    background-color: rgb(0, 189, 213);
    color: #f5fbff;
    border-radius: 4px;
    border: 1px solid transparent;
    width: fit-content;
    font-size: 1.75rem;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    line-height: 1.75;
    min-width: 64px;
    transition:
      background-color 250ms,
      color 250ms,
      border-color 250ms;
    text-transform: uppercase;
    &:hover {
      background-color: #f5fbff;
      color: rgb(0, 189, 213);
      border-color: rgb(0, 189, 213);
    }
  }

  &__cardLanding {
    max-height: 450px;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow:
      rgb(0 0 0 / 20%) 0px 2px 1px -1px,
      rgb(0 0 0 / 14%) 0px 1px 1px 0px,
      rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    overflow: hidden;
    max-width: 345px;
    border-radius: 1.5rem;
    background-color: rgb(255, 255, 255);
    z-index: 100;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    h3 {
      padding: 16px;
      padding-bottom: 24px;
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      line-height: 1.3;
    }
    @media screen and (max-width: 600px) {
      h3 {
        font-size: 18px;
        padding: 16px 16px 24px 16px;
      }
    }
  }

  &__roadMapAndText {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    &__roadMap {
      width: 80%;
      margin-bottom: 2rem;
      padding-top: 2rem;
    }
    &__roadMapImage {
      width: 50%;
      color: rgba(0, 0, 0, 0.87);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow:
        rgb(0 0 0 / 20%) 0px 2px 1px -1px,
        rgb(0 0 0 / 14%) 0px 1px 1px 0px,
        rgb(0 0 0 / 12%) 0px 1px 3px 0px;
      overflow: hidden;
      border-radius: 1.5rem;
      background-color: rgb(255, 255, 255);
      z-index: 100;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      margin: 1rem 3rem 3rem 3rem;
      padding: 2rem;
      @media screen and (max-width: 600px) {
        grid-row: span 1;
        margin-top: 2rem;
      }
      &__figure {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-row: 1 / 2;
        margin: 1rem 3rem 0rem;
        @media screen and (max-width: 600px) {
          grid-row: span 1;
          margin: 0;
        }
      }
    }
    &__roadMapText {
      width: 100%;
      padding: 45px;
      h4 {
        font-size: 32px;
        font-weight: 400;
        // line-height: 28.8px;
        margin-bottom: 1rem;
        background: linear-gradient(
          270deg,
          #00bdd5 0%,
          #00ccf2 25%,
          #1c7391 100%
        );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      p {
        font-size: 24px;
        text-align: center;
        line-height: 1.8;
        font-weight: 500;
      }
    }
    @media screen and (max-width: 900px) {
      &__roadMapImage {
        width: 100%;
      }
      &__roadMapText {
        width: 100%;
        padding: 0;
        h4 {
          font-size: 2rem;
          line-height: unset;
          text-align: center;
        }
        p {
          font-size: 1.35rem;
          font-weight: 400;
          line-height: 1.5;
          text-align: center;
          padding-inline: 0.5rem;
          text-align: justify;
        }
      }
    }
  }

  &__footer {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-inline: 5%;
    gap: 0.5rem;
    h3 {
      font-family: $secondary;
      font-size: 2rem;
    }
    @media screen and (max-width: 600) {
      p {
        width: unset;
        padding-inline: 1rem;
      }
    }
  }
}
