@import '../../themes/patterns';

.dashboard {
  display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(200px, 100%));
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  &__disabledInput {
    border-bottom: none;
  }
  &--span-3 {
    grid-column: span 3;
  }
  &--span-2 {
    grid-column: span 2;
  }
  &--span-1 {
    grid-column: span 1;
  }
  @include xxs {
    display: flex;
    flex-direction: column;
    &--span-2 {
      display: none;
    }
  }
}

.dashboardMediaCard {
  @extend .dashboardCard;
  display: flex;
  flex-direction: row !important;
  gap: 1.5rem;
  &__media {
    span {
      border-radius: 10px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1.5rem;
    h5 {
      font-family: $primary;
      font-weight: 700;
      color: #000000;
      font-size: 1.25rem;
    }
    p {
      font-family: $primary;
      font-weight: 400;
      color: #5e636b;
      font-size: 1rem;
    }
  }
  @include xxs {
    flex-direction: column !important;
    align-items: center;
  }
}

.mediaCard {
  &__mediaSection {
    display: flex;
    justify-content: space-between;
    &--iconButtons {
      display: flex;
      gap: 0.5rem;
    }
    @include xxs {
      align-items: center;
      span {
        display: none;
      }
    }
  }
  &__mediaFooter {
    text-align: right;
    font-family: $primary;
    color: #5e636b;
    margin-top: 0.5rem;
    font-size: 0.75rem !important;
  }
}

.dashboardCard {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  box-shadow: $card-shadow;
  border-radius: $paper-radius;
  background-color: #ffffff;
  min-height: 11rem;
  height: 100%;
  &__content {
    flex: 1;
  }
}

.tokenImg {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 15rem;
  border: 0.5px solid $light-grey;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.transactionsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    font-family: $primary;
    font-weight: 700;
    color: #5e636b;
  }
  a {
    color: $blueGreen;
    font-family: $primary;
    font-weight: 700;
  }
}

.cardTitle {
  display: flex;
  gap: 1rem;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
    h5 {
      font-weight: 700;
      font-family: $primary;
      font-size: 1rem;
      color: #5e636b;
    }
    p {
      color: $table-grey;
      font-family: $primary;
      font-size: 0.75rem;
      font-weight: medium;
    }
  }
}

.tokenTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  h5 {
    font-weight: 700;
    color: #5e636b;
    font-family: $primary;
    font-size: 0.75rem;
  }
  &__symbol-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    span:nth-child(1) {
      font-family: $primary;
      font-weight: 700;
      font-size: 1rem;
    }
    span:nth-child(2) {
      font-family: $primary;

      color: #5e636b;
      font-weight: 700;
      font-size: 0.75rem;
    }
  }
}

.TokenBalanceContent {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  gap: 0.5rem;
  p {
    text-transform: uppercase;
    font-family: $primary;
    color: #5e636b;
    font-size: 0.75rem;
    font-weight: 700;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 2fr));
    align-items: center;
    div {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
    span {
      font-weight: bold;
    }
  }
}
