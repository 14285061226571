.loginEmail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $primary;
  &__logo {
    height: 6.75rem;
    width: 31.8rem;
    margin-bottom: 2.125rem;
  }
  &__left {
    background: linear-gradient(
      180deg,
      rgba(28, 115, 145, 0.86) 0%,
      rgba(0, 189, 213, 0.86) 67.71%
    );
    transform: rotate(-180deg);
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      transform: rotate(180deg);
      text-align: center;
      > span {
        min-width: 100px;
        background: white;
        border-radius: 20px;
        padding: 20px 10px;
        margin-bottom: 5vh;
      }
      > h1 {
        font-size: 40px;
        font-weight: $bold;
        color: white;
        margin-bottom: 5vh;
      }
      > p {
        font-size: 15px;
        font-weight: $medium;
        margin-bottom: 8vh;
        color: white;
        width: 90%;
      }
    }
    &__button {
      > p {
        color: white;
      }
      border: 1px solid white;
    }
  }
  > h1 {
    font-weight: $medium;
    font-size: 1.538rem;
    margin-bottom: 5.625rem;
  }
  &__p {
    margin-top: 0.5rem;
    font-weight: $medium;
    color: $table-grey;
    font-size: 1.25rem;
    margin-bottom: 2.5rem;
    &__link {
      text-decoration: none;
      font-weight: $bold;
      color: $blueGreen;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    > h1 {
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 59px;
      text-align: center;
      margin-bottom: 5vh;
      margin-top: 5vh;
      color: #00bdd5;
    }
    > p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 5vh;
      text-align: center;
      color: #bdbdbd;
    }
    &__input {
      background-color: transparent;
      text-align: left;
    }
    &__inputMain {
      // margin-bottom: 5rem;
    }
    &__button {
      width: 11.25rem;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 100vh;
    width: 100%;
    &__logo {
      width: 15.8rem;
      height: 3.375rem;
      margin-bottom: 1.125rem;
    }
    &__left {
      height: 100vh;
    }
    > h1 {
      font-weight: $medium;
      font-size: 0.9375rem;
      margin-bottom: 3.8rem;
    }
    &__p {
      margin-top: 0.937rem;
      font-size: 0.75rem;
      margin-bottom: 2.2rem;
    }
    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      > h1 {
        font-size: 40px;
      }
      > h2 {
        font-weight: $bold;
        font-size: 0.937rem;
        margin-bottom: 3.06rem;
      }
      &__input {
        width: 20rem;
        height: 3.5rem;
      }
      &__button {
        width: 20rem;
      }
    }
  }
}
