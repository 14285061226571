@import '../../themes/patterns';

.buttonSidebar {
  position: relative;
  display: flex;
  justify-content: flex-start;
  outline: 0;
  width: 100%;
  height: 1.5rem;
  border: none;
  cursor: pointer;
  background-color: transparent;
  margin-top: 1rem;
  margin-bottom: 1rem;
  &--divider {
    width: 100%;
    height: 1.5rem;
    border: none;
    cursor: pointer;
    background-color: transparent;
    margin-top: unset !important;
    &::before {
      margin-bottom: 1rem;
      content: '';
      display: block;
      height: 1.5px;
      // background-color: $blue-lighter;
      opacity: 0.5;
      border-radius: 1px;
      width: 75%;
      margin-inline: auto;
      overflow: hidden;
    }
  }
  &__blob {
    background: transparent;
    width: 4px;
    height: 100%;
    border-radius: 10px;
    &--active {
      @extend .buttonSidebar__blob;
      background: $white;
      position: absolute;
    }
  }
  &__children {
    align-items: center;
    color: $white;
    display: flex;
    height: 100%;
    margin-left: 1.8rem;
    opacity: 0.7;
    position: relative;
    > p {
      font-size: 1.1rem;
      font-weight: $semi-bold;
      margin-left: 0.5rem;
      text-align: left;
      white-space: nowrap;
    }
    > svg {
      height: 35px;
      padding: 0.5rem;
      stroke: $white;
      width: 35px;
    }
    &--active {
      @extend .buttonSidebar__children;
      opacity: 1;
    }
  }
  &__icon2 {
    > svg {
      align-items: center;
      display: flex;
      height: 2.2rem;
      justify-content: center;
      margin-left: 1rem;
      padding: 0.2rem;
      stroke: $white;
      width: 2.2rem;
    }
  }
  &__tooltip {
    align-items: center;
    background-color: $white !important;
    border-radius: 10px;
    // border: 0.5px solid $blue;
    box-shadow: 0px 15px 30px #1466cc29;
    color: $blue !important;
    display: none;
    height: 2rem;
    justify-content: center;
    left: 70px;
    min-height: 2rem;
    padding: 1.2rem 1rem !important;
    position: absolute;
    top: -7px;
    width: max-content;
    font-weight: $medium !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 0.85rem;
    height: 4rem;
    margin-top: 1rem;
    &__icon2 {
      > svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
    &__children {
      > p {
        font-size: 0.85rem;
      }
    }
  }
}

.buttonsSidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}
