@import '../../themes/patterns';

.buttonPassword {
  width: fit-content;
  height: 3.12rem;
  border: 1px solid $blueGreen;
  border-radius: 14px;
  opacity: 1;
  background: $white;
  color: $blueGreen;
  font-family: $primary;
  font-weight: $bold;
  font-size: 1rem;
  cursor: pointer;
  padding: 0 2rem;

  @media screen and (max-width: 1024px) {
    height: 2.62rem;
    font-size: 0.75rem;
    margin-bottom: 2rem;
  }
}
