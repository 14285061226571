@import '../../themes/patterns';

.settings {
  display: flex;
  flex-direction: column;
  margin: 4rem 0 0 0;
  > h1 {
    margin-bottom: 2rem;
    margin-left: 1.5rem;
  }
  &__optionsContainer {
    width: 100%;
    max-width: 50rem;
    font-size: 1.5rem;
    font-weight: $medium;
    color: $title-grey;
  }
  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    border-radius: 10px;
    padding: 1rem;
    cursor: pointer;
    > svg {
      height: 1.5rem;
      width: auto;
    }
    &:hover {
      background: $blue;
      color: white;
      > svg {
        stroke: white;
      }
      .settings__name {
        > svg {
          stroke: white;
        }
      }
    }
  }
  &__optionSelect {
    @extend .settings__option;
    cursor: unset;
  }
  &__name {
    display: flex;
    > svg {
      width: 1.75rem;
      height: 1.75rem;
      margin-right: 1rem;
    }
  }
  &__select {
    width: 10rem;
    font-weight: $medium;
    border-radius: 4px;
    font-family: $primary;
    font-size: 1.25rem;
  }

  @media screen and (max-width: 1024px) {
    > h1 {
      font-size: 1rem;
      margin: 0 0 1rem 0;
      text-align: center;
    }
    &__optionsContainer {
      width: 100%;
      height: 50%;
      font-size: 0.9rem;
    }
    &__option {
      padding-right: 1.5rem;
      > svg {
        height: 1rem;
      }
    }
    &__name {
      display: flex;
      align-items: center;
      > svg {
        height: 1.75rem;
        margin-right: 1rem;
      }
    }
    &__select {
      width: 5.5rem;
    }
  }
}
