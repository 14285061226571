@import '../../themes/patterns';

.inputFile {
  position: relative;
  display: flex;
  align-items: center;
  color: $table-grey;
  font-family: $primary;
  width: 100%;
  height: 3rem;
  font-weight: $regular;
  font-size: 1.125rem;
  border: none;
  border-radius: 14px;
  background-color: $light-grey;
  padding: 0rem 1rem;
  outline: none;
  cursor: pointer;
  &__input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  &__error {
    color: $red_error;
    font-weight: $medium;
    font-size: 1rem;
    position: absolute;
    margin-top: 9.5rem;
  }
  &__icons {
    display: flex;
    align-items: center;
    > svg {
      width: 1.875rem;
      height: 1.875rem;
      margin-right: 0.8rem;
      stroke: $blueGreen-dark;
    }
  }
  &__required {
    color: red;
    position: absolute;
    right: -0.5rem;
    font-size: 1rem;
    font-weight: $medium;
    top: 0;
  }

  @media screen and (max-width: 1024px) {
    height: 2.6rem;
    font-weight: $medium;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    &__icons {
      > svg {
        width: 1.125rem;
        height: 1.125rem;
      }
    }
  }
}
