@import './_patterns.scss';

@mixin newBankFields {
  input.PhoneInputInput,
  .PhoneInput,
  select,
  input {
    font-size: 1rem;
    letter-spacing: 0;
    width: 302px;
  }
  select.PhoneInputCountrySelect {
    width: 2rem;
  }
  input.checkboxMain__checkbox {
    width: auto;
  }
  input.inputMain__input {
    margin-bottom: 0.5rem;
  }
}

@mixin buttonStandardStyles {
  outline: none;
  cursor: pointer;
  padding: 0.786rem 2.28rem;
  p {
    font-size: 0.857rem;
    font-weight: #{$bold};
    font-family: #{$secondary};
  }
  &:disabled {
    cursor: default;
  }
  @include xxs {
    min-width: unset;
  }
}

@mixin xxs {
  @media (max-width: 399px) {
    @content;
  }
}

@mixin xs {
  @media (min-width: #{$xs-max}) and (max-width: 599px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$sm-max}) and (max-width: 899px) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$md-max}) and (max-width: 1199px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$lg-max}) and (max-width: 1536px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$xl-max}) {
    @content;
  }
}
