@import '../../themes/patterns';

.faq {
  background-color: $white;
  box-shadow: $card-shadow;
  border-radius: $card-radius;
  min-height: fit-content;
  padding: 4rem;
  &__title {
    display: flex;
    padding-bottom: 2rem;
    h2 {
      background: $text-gradient;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: $icon-shadow;
      font-size: 1.5rem;
      font-weight: 700;
      font-family: $primary;
    }
  }
  &__content {
    padding-inline: 1 rem;
  }
}
