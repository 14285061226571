@import '../../themes/patterns';

.termsConditionsMain {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 250;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  &__container {
    position: absolute;
    top: 3.125rem;
    margin-bottom: 3.125rem;
    width: 65.75rem;

    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding: 4.75rem 4.3rem 2.7rem 2.125rem;
    display: flex;
    flex-direction: column;
    > h1 {
      font-family: $primary;
      font-weight: $bold;
      font-size: 1.875rem;
      color: $blue;
      margin-bottom: 2.5rem;
    }
    > p {
      font-size: 1.25rem;
      font-weight: $regular;
      color: $title-grey;
      margin-bottom: 2rem;
    }
    &__button {
      width: 23.75rem;
      align-self: center;
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 4px;
  }
  &::-webkit-scrollbar-button:increment {
    display: none;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 0.5px solid #f1f2f3;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  @media screen and (max-width: 1024px) {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $white;
    z-index: 100;
    &__container {
      width: 100%;
      padding: 2rem 2rem 2.7rem 2rem;
      display: flex;
      flex-direction: column;
      box-shadow: none;
      > h1 {
        font-size: 1.125rem;
        margin-bottom: 2.44rem;
        text-align: center;
      }
      > p {
        font-size: 0.625rem;
        margin-bottom: 2.43rem;
      }
      &__button {
        width: 100%;
      }
    }
  }
}
