@import '../../themes/patterns';

.buttonRed {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  width: 100%;
  height: 3.8rem;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 0px $white;
  border-radius: 10px;
  padding: 0.625rem 1rem;
  background-color: unset;
  &:disabled {
    background-color: $table-grey;
    cursor: default;
    > p {
      color: $white;
      font-size: 1.25rem;
      font-weight: $semi-bold;
      font-family: $secondary;
    }
    > label {
      display: none;
    }
    &:hover {
      > label {
        display: inline;
        position: absolute;
        width: auto;
        max-width: 20rem;
        height: auto;
        opacity: 0.8;
        bottom: 3rem;
        color: $white;
        font-weight: $semi-bold;
        padding: 0.5rem;
      }
    }
  }
  > p {
    font-family: $secondary;
    font-size: 1rem;
    font-weight: $semi-bold;
    color: $red;
  }
  &--active {
    @extend .buttonRed;
    color: $red;
    border: 1px solid $red;
    > p {
      color: $red;
    }
    &:hover {
      background: $red;
      border: 1px solid transparent;
      > p {
        color: $white;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    height: 3.5rem;
    > p {
      font-size: 0.875rem;
    }
    &:disabled {
      > label {
        display: none;
      }
      &:hover {
        > label {
          max-width: 15rem;
          bottom: 5rem;
          padding: 0.7rem;
        }
      }
    }
  }
}
