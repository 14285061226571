@import '../../themes/patterns';

.nav {
  align-items: center;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #272d3b33;
  color: $title-grey;
  display: flex;
  height: 4.375rem;
  padding: 0 2rem;
  padding-left: calc(190px + 3rem);
  position: fixed;
  right: 0;
  top: -1px;
  transition: all 0.2s ease;
  width: 100%;
  z-index: 200;
  &__crypto-wallet {
    margin-left: 1rem;
    height: 2.6rem;
    box-shadow:
      0px 2.03356px 2.03356px rgba(0, 0, 0, 0.14),
      0px 1.01678px 5.08389px rgba(0, 0, 0, 0.12),
      0px 3.05033px 1.01678px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding-inline: 1rem;
    cursor: pointer;
    &--unsupported {
      background-color: $red;
      color: $white;
      cursor: pointer;
      > p {
        color: $white;
      }
    }
    &--notconnected {
      background-color: $yellow;
      color: $white;
      cursor: pointer;
      > p {
        color: $white;
      }
    }
    &__unsupported {
      &--wording {
        display: flex;
        flex-direction: column;
        > p:first-child {
          font-size: $text-sm;
          white-space: nowrap;
          color: $black !important;
        }
        > p:nth-child(2) {
          text-align: right;
          color: $table-grey !important;
          font-size: $text-xs;
        }
      }
    }
  }
  &__identicon {
    border-radius: 50%;
  }
  &__navigation {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  &__logo {
    display: none;
  }
  &__profile {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
  }
  &__username {
    font-size: 1rem;
    font-weight: $bold;
    color: $title-grey;
    width: max-content;
  }
  &__registration {
    width: 16.3125rem;
    height: 3rem;
  }
  &__button {
    margin-left: 1rem;
    outline: 0;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 2.5rem;
    > svg {
      cursor: pointer;
      height: 25px;
      width: 35px;
    }
    &:last-child {
      display: none;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 0 0.8rem;
    &__navigation {
      display: none;
    }
    &__logo {
      display: block;
      cursor: pointer;
      width: 2rem;
    }
    &__profile {
      width: 100%;
      justify-content: flex-end;
      .MuiBox-root {
        padding-top: 2px;
      }
    }
    &__username {
      font-size: 0.9rem;
      letter-spacing: 0px;
      font-weight: $bold;
      color: $title-grey;
      margin-right: 1rem;
    }
    &__registration {
      margin-left: 1rem;
      padding: 0.6rem 0.5rem;
      width: 100%;
      height: 2.8125rem;
    }
    &__button {
      margin-left: 1rem;
      height: 2rem;
      width: 2rem;
      > svg {
        height: 25px;
        width: 35px;
      }
      &:last-child {
        display: flex;
      }
    }
  }
}
