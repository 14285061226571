@import '../../themes/patterns';

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  min-height: 50vh;
  background-color: $light-grey;
  padding-top: 15vh;
  padding-bottom: 4rem;
  &__404 {
    font-size: 8rem;
    color: $blueGreen;
  }
  &__title {
    color: $title-grey;
    font-size: 3.5rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 0.75rem;
    padding: 1.5rem;
    text-align: center;
    &__404 {
      font-size: 5rem;
    }
    &__title {
      font-size: 2rem;
      text-align: center;
    }
  }
}
