@import '../../themes/patterns';
@import '../../themes/mixins';
//BUTTONGROUP
.buttonsGroupContainer {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  &__button {
    display: flex;
    flex: 1 0;
    gap: 0.3rem;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 6px;
    border: 1px solid #f4f6f8;
    border-radius: 8px;
    box-shadow: $button-shadow;
    opacity: 1;
    cursor: pointer;
    &__active {
      background-color: $light-grey;
      display: flex;
      flex: 1 0;
      gap: 0.3rem;
      justify-content: center;
      align-items: center;
      padding: 6px;
      border: 1px solid $light-grey;
      border-radius: 8px;
      box-shadow: $button-shadow;
      opacity: 1;
      cursor: pointer;
    }
  }
  div {
    margin-right: 2px;
    margin-top: 2px;
    svg {
      color: #38718e;
    }
  }
  p {
    font-size: 1rem;
  }

  @include xxs {
    &__button {
      flex-direction: column !important;
      padding: 5px;
      &__active {
        flex-direction: column !important;
        padding: 5px;
        background-color: $light-grey;
      }
    }
  }
}
