@import '../../themes/patterns';
@import '../../themes/mixins';

.signMessageModal {
  position: relative;
  background-color: $white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  border-radius: $paper-radius;
  box-shadow: $paper-shadow;
  @include xxs {
    margin-inline: auto;
    max-width: 375px;
  }
  > div > h3 {
    font-size: $text-2xl;
    background: linear-gradient(270deg, #00bdd5 0%, #00ccf2 25%, #1c7391 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0.15em;
  }
  > div > p {
    color: $grey;
    line-height: 1.6;
  }
  &__header {
    display: flex;
    align-items: center;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      transform: translate(0px, 20px);
      height: 1px;
      background-color: $light-grey;
    }
    > span {
      flex: 1;
      font-size: $text-sm;
      color: $blueGreen;
      font-weight: $bold;
    }
    > svg {
      height: 1.5rem;
      width: 1.5rem;
      stroke-width: 2px;
      cursor: pointer;
    }
  }
}
