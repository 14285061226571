.roadMap {
  &__text {
    font-weight: $semi-bold;
    font-size: 20px;
    padding-top: 1rem;
    text-align: center;
    &--left {
      @extend .roadMap__text;
    }
    &--right {
      @extend .roadMap__text;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 10px 30px 10px 1fr;
    grid-auto-rows: minmax(100px, auto);
    padding: 1rem;
  }
  &__bodyColor {
    &--c1 {
      grid-column: 3;
      grid-row: 1;
      background: #1c6682;
      border-radius: 2rem 2rem 0 0;
    }
    &--c2 {
      background: #1a7c9d;
      grid-column: 3;
      grid-row: 2;
    }
    &--c3 {
      background: #1c9dbe;
      grid-column: 3;
      grid-row: 3;
    }
    &--c4 {
      background: #38bad8;
      grid-column: 3;
      grid-row: 4;
    }
    &--c5 {
      background: #aee8f3;
      grid-column: 3;
      grid-row: 5;
    }
    &--c6 {
      background: #eefbfd;
      grid-column: 3;
      grid-row: 6;
      border-radius: 0rem 0rem 2rem 2rem;
    }
  }
  &__arrowLeft {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #1c6682;
    align-self: center;
    &--arr2 {
      @extend .roadMap__arrowLeft;
      grid-column: 4;
      grid-row: 2;
      border-left: 10px solid #1a7c9d;
    }
    &--arr4 {
      @extend .roadMap__arrowLeft;
      grid-column: 4;
      grid-row: 4;
      border-left: 10px solid #38bad8;
    }
    &--arr6 {
      @extend .roadMap__arrowLeft;
      grid-column: 4;
      grid-row: 6;
      border-left: 10px solid #eefbfd;
    }
  }
  &__arrowRight {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #1c6682;
    align-self: center;
    &--arr3 {
      @extend .roadMap__arrowRight;
      grid-column: 2;
      grid-row: 3;
      border-right: 10px solid #1c9dbe;
    }
    &--arr5 {
      @extend .roadMap__arrowRight;
      grid-column: 2;
      grid-row: 5;
      border-right: 10px solid #aee8f3;
    }
  }
  &__bodyYear {
    &--one {
      padding-top: 3rem;
      padding-bottom: 10rem;
      grid-column: 1;
      grid-row: 1/ 3;
    }
    &--two {
      padding-top: 3rem;
      padding-bottom: 10rem;
      grid-column: 5;
      grid-row: 2/ 4;
    }
    &--tree {
      padding-top: 3rem;
      padding-bottom: 10rem;
      grid-column: 1;
      grid-row: 3/ 5;
    }
    &--four {
      padding-top: 3rem;
      padding-bottom: 10rem;
      grid-column: 5;
      grid-row: 4/ 6;
    }
    &--five {
      padding-top: 3rem;
      padding-bottom: 10rem;
      grid-column: 1;
      grid-row: 5/ 7;
    }
    &--six {
      padding-top: 3rem;
      grid-column: 5;
      grid-row: 6;
    }
    &__hexagono {
      position: relative;
      margin: 0 auto;
      width: 94px;
      height: 150px;
      box-sizing: border-box;
      border: 10px solid transparent;
      border-radius: 10px;
      &--2023 {
        @extend .roadMap__bodyYear__hexagono;
        background-color: #1c6682;
        border-top-color: #1c6682;
        border-bottom-color: #1c6682;
      }
      &--2022-2 {
        @extend .roadMap__bodyYear__hexagono;
        background-color: #1a7c9d;
        border-top-color: #1a7c9d;
        border-bottom-color: #1a7c9d;
      }
      &--2022-1 {
        @extend .roadMap__bodyYear__hexagono;
        background-color: #1c9dbe;
        border-top-color: #1c9dbe;
        border-bottom-color: #1c9dbe;
      }
      &--2021 {
        @extend .roadMap__bodyYear__hexagono;
        background-color: #38bad8;
        border-top-color: #38bad8;
        border-bottom-color: #38bad8;
      }
      &--2020 {
        @extend .roadMap__bodyYear__hexagono;
        background-color: #aee8f3;
        border-top-color: #aee8f3;
        border-bottom-color: #aee8f3;
      }
      &--2019 {
        @extend .roadMap__bodyYear__hexagono;
        background-color: #eefbfd;
        border-top-color: #eefbfd;
        border-bottom-color: #eefbfd;
      }
      &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        right: -50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        color: #eefbfd;
        font-size: 2rem;
        font-weight: $bold;
        &--hex2020,
        &--hex2019 {
          @extend .roadMap__bodyYear__hexagono__text;
          color: black;
        }
        p {
          font-size: 14px;
        }
      }
    }
    &__hexagono:before,
    &__hexagono:after {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      background: inherit;
      border-radius: inherit;
      height: 115%;
      width: 128%;
      border: inherit;
    }
    &__hexagono:before {
      transform: rotate(60deg);
    }
    &__hexagono:after {
      transform: rotate(-60deg);
    }
  }
  @media screen and (max-width: 900px) {
    &__bodyColor {
      &--c1,
      &--c2,
      &--c3,
      &--c4,
      &--c5,
      &--c6 {
        display: none;
      }
    }
    &__arrowRight,
    &__arrowLeft {
      display: none;
    }
    &__body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: minmax(100px, auto);
      padding: 1rem;
    }
    &__bodyYear {
      &--one {
        padding: 2rem 0rem !important;
        grid-column: 1/3;
        grid-row: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      &--two {
        padding: 2rem 0rem !important;
        grid-column: 1/3;
        grid-row: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &--tree {
        padding: 2rem 0rem !important;
        grid-column: 1/3;
        grid-row: 3;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      &--four {
        padding: 2rem 0rem !important;
        grid-column: 1/3;
        grid-row: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &--five {
        padding: 2rem 0rem !important;
        grid-column: 1/3;
        grid-row: 5;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      &--six {
        padding: 2rem 0rem !important;
        grid-column: 1/3;
        grid-row: 6;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  @media screen and (max-width: 600px) {
    &__text {
      font-size: 16px;
    }
    &__bodyYear {
      &__hexagono {
        height: 100px !important;
        width: 65px !important;
        &__text {
          font-size: 16px;
          p {
            font-size: 12px;
          }
        }
      }
      &__hexagono:before,
      &__hexagono:after {
        height: 125%;
        width: 145%;
      }
    }
  }
}
