@import '../../themes/patterns';

.accordion {
  display: flex;
  flex-direction: column;
  height: 4.1rem;
  > * {
    padding: 1rem;
  }
  summary::-webkit-details-marker {
    display: none;
  }
  &__title {
    align-items: center;
    background: #ffffff;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid $table-grey;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-family: $primary;
    color: $blueGreen;
    font-weight: 600;
    font-size: 1.25rem;
    &--small {
      @extend .accordion__title;
      font-size: 1rem;
      color: $black;
    }
  }
  &__actions {
    display: flex;
    margin-right: 0.5rem;
    svg {
      border-radius: 100%;
      cursor: pointer;
      height: 1.75rem;
      margin-left: 1rem;
      stroke: $blue;
      width: 1.75rem;
    }
  }
  &__arrow {
    transform: rotate(0);
    transition: 0.25s ease-in;
    &--active {
      transform: rotate(90deg);
      transition: 0.25s ease-out;
    }
  }
  &__content {
    line-height: 1.6rem;
    ol,
    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    p {
      margin-bottom: 0.5rem;
    }
  }
  @media screen and (max-width: 1024px) {
    &__actions {
      display: flex;
    }
  }
}
