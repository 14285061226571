@import '../../themes/patterns';

.tokenSaleContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  height: 100%;
  &__rates {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-family: $primary;
    h4 {
      font-size: 1.2rem;
    }
    p {
      font-size: 0.9rem;
      color: $table-grey;
    }
  }
}
