@import '../../themes/patterns';

.tooltip {
  width: 17.875rem;
  display: flex;
  flex-direction: column;
  &__container {
    min-height: 3rem;
    background-color: $blue;
    font-size: 0.9rem;
    font-weight: $medium;
    letter-spacing: -0.74px;
    color: $white;
    padding: 0.875rem 1.5rem;
    opacity: 1;
  }
  &__triangle {
    align-self: flex-end;
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 80%;
    background-color: $blue;
    opacity: 1;
    // clip-path: polygon(100% 0, 0 0, 50% 60%);
    position: absolute;
    top: -1.5rem;
    clip-path: polygon(0 100%, 50% 50%, 100% 100%);
  }

  @media screen and (max-width: 1024px) {
    width: 11.3125rem;
    &__container {
      min-height: 2.625rem;
      font-size: 0.625rem;
      letter-spacing: -0.45px;
      padding: 0.625rem 0.875rem;
    }
    &__triangle {
      width: 1.125rem;
      height: 1.125rem;
      margin-right: 7.5%;
      top: -1rem;
    }
  }
}
