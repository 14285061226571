//Colors
$black: #000000;
$white: #ffffff;
$orange: #f2994a;
$orange2: #ff5b00;
$yellow: #ffc235;
$green: #2ed47a;
$pink: #dc4f89;
$red: #f7685b;
$red2: #e1534f;
$red_error: #fb6363;
$title-grey: #333333;
$light-grey: #e6e6e6;
$table-grey: #b0b3b7;
$grey: #6e6e6e;

// Primary color
$blue-lighter: #f5fbff;
$blue-light: #7adae7;
$blue: #00bdd5;
$blue-dark: #08c5dd;

// Secondary color
$blueGreen-lighter: #00bdd51a;
$blueGreen-light: #5395a8;
$blueGreen: #1c7391;
$blueGreen-dark: #2c3e50;

$text-gradient: linear-gradient(270deg, #00bdd5 0%, #00ccf2 25%, #1c7391 100%);

//Tipography
$primary: Sora;
$secondary: Overpass;
$tertiary: Overpass;

//Weights
$bold: 700;
$semi-bold: 600;
$medium: 500;
$regular: 400;
$light: 300;

//responsive breakpoints
$xxs-max: 0px;
$xs-max: 400px;
$sm-max: 600px;
$md-max: 900px;
$lg-max: 1200px;
$xl-max: 1536px;

$paper-border: 1px solid rgba(237, 237, 237, 0.3);

$button-shadow: 0px 2px 4px rgb(39 45 59 / 20%);

$shadow: #00000035;
$paper-shadow: 0px 3px 6px rgb(39 45 59 / 20%);
$card-shadow: 0px 0px 10px $shadow;
$icon-shadow: 0px 1px 3px #00000050;
$chip-shadow:
  0px 2px 2px rgba(0, 0, 0, 0.14),
  0px 1px 5px rgba(0, 0, 0, 0.12),
  0px 3px 1px rgba(0, 0, 0, 0.04);

$card-radius: 20px;
$paper-radius: 12px;
$chip-radius: 5px;

$text-xs: 0.75rem;
$text-sm: 0.875rem;
$text-base: 1rem;
$text-lg: 1.125rem;
$text-xl: 1.25rem;
$text-2xl: 1.5rem;
$text-3xl: 1.875rem;
$text-4xl: 2.25rem;
