@import '../../themes/patterns';

.mainSidebar {
  // background: $blue 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #272d3b33;
  font-family: Montserrat;
  color: $white;
  width: 215px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 201;
  transition: all 0.2s ease;
  &__toggleButton {
    margin-bottom: 2rem;
  }
  .buttonSidebar__dashboardButtons .buttonSidebar__children {
    svg {
      margin-left: 1.5rem;
    }
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.mainSidebar--active {
  @extend .mainSidebar;
  width: 80px;
  min-width: auto;
  .logoBrand .logoBrand__brand {
    display: none;
  }
  .logoBrand {
    margin-left: 0;
    justify-content: center;
  }
  .buttonSidebar__children {
    opacity: 0.6;
  }
  .buttonSidebar__children,
  .buttonSidebar__children--active {
    > p {
      display: none;
    }
  }
  .buttonSidebar:hover {
    .buttonSidebar__tooltip {
      display: flex;
    }
  }
  .buttonSidebar__icon2 {
    display: none;
  }
  .buttonSidebar__blob,
  .buttonSidebar__blob--active {
    display: none;
  }
  .mainContainer {
    width: 100%;
    padding-left: 100px;
  }
  .mainLayoutContainer__content {
    margin: 100px !important;
  }
  .buttonSidebar__dashboardButtons {
    margin-left: 0;
  }
  .buttonSidebar__dashboardButtons .buttonSidebar__children {
    svg {
      margin-left: 0rem;
    }
  }
  .buttonSidebar__dashboardButtons .buttonSidebar__children--active {
    svg {
      opacity: 1;
      stroke: white;
    }
  }
}
