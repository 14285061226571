@import '../../themes/patterns';

.checkboxMain {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: $primary;
  font-weight: $semi-bold;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  &__container {
    display: flex;
    flex-direction: column;
  }
  > p {
    width: auto;
    font-size: 13px;
    font-weight: $regular;
  }
  > span {
    width: auto;
  }
  &__checkbox {
    width: 2rem;
    height: 1rem;
  }
  &__required {
    color: red;
    position: absolute;
    right: -0.6rem;
    font-size: 1rem;
    font-weight: $medium;
    top: 0;
  }
  &__investmentInfo {
    display: flex;
    align-items: center;
    > p {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.74px;
    }
  }

  @media screen and (max-width: 1024px) {
    font-size: 0.75rem;
    width: 100%;
    &__checkbox {
      margin-right: 0.5rem;
      width: 0.8rem;
      height: 0.8rem;
    }
    > p {
      width: fit-content;
      margin-right: 0.1rem;
      font-size: 13px;
      font-weight: 400;
    }
    > span {
      width: auto;
      font-size: 0.8rem !important;
    }
  }
}
