@import '../../themes/patterns';

.loginMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $primary;
  height: 100%;
  &__wallets {
    width: 100%;
    display: grid;
    place-items: center;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }
  &__logo {
    height: 10vh;
    min-height: 50px;
    object-fit: contain;
    margin-bottom: 2vh;
  }
  &__right {
    background: linear-gradient(
      180deg,
      rgba(28, 115, 145, 0.86) 0%,
      rgba(0, 189, 213, 0.86) 67.71%
    );
    // transform: rotate(-180deg);
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      // transform: rotate(180deg);
      text-align: center;
      > span {
        min-width: 100px;
        background: white;
        border-radius: 30px;
        padding: 20px 10px;
        margin-bottom: 5vh;
      }
      > h1 {
        font-size: 40px;
        font-weight: $bold;
        color: white;
        margin-bottom: 5vh;
      }
      > p {
        font-size: 15px;
        font-weight: $medium;
        margin-bottom: 8vh;
        color: white;
        width: 90%;
      }
    }
    &__button {
      > p {
        color: white;
      }
      border: 1px solid white;
    }
  }
  > h2 {
    font-weight: $bold;
    font-size: 1.25rem;
    margin-bottom: 5.625rem;
  }
  > h1 {
    font-weight: $medium;
    font-size: 1.538rem;
    margin-bottom: 10vh;
  }
  &__p {
    margin-top: 0.5rem;
    font-weight: $medium;
    color: $table-grey;
    font-size: 1.25rem;
    margin-bottom: 2.5rem;
    &__link {
      text-decoration: none;
      font-weight: $bold;
      color: $blueGreen;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > h1 {
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 59px;
      text-align: center;
      margin-bottom: 3rem;
      color: #00bdd5;
    }
    > p {
      color: $title-grey;
      margin: 3rem 0rem;
    }
  }
  &__inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > p {
      text-align: left;
      width: 100%;
      font-size: 1rem;
      color: $table-grey;
      font-weight: $medium;
    }
    &__input {
      background-color: transparent;
      width: 21.125rem;
      text-align: left;
      margin-bottom: 1rem;
    }
  }
  &__link {
    font-size: 1.25rem;
    text-decoration: none;
    font-weight: $medium;
    color: $title-grey;
    margin: 1vh 0 3vh 0;
    &--mobile {
      @extend .loginMain__link;
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 100vh;
    &__logo {
      width: 15.8rem;
      height: 3.375rem;
      margin-bottom: 2.125rem;
    }
    > h1 {
      font-weight: $medium;
      font-size: 0.9375rem;
    }
    &__p {
      margin-top: 0.937rem;
      font-size: 0.75rem;
      margin-bottom: 2.2rem;
    }
    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10vh;
      > h1 {
        font-size: 40px;
      }
    }
    &__inputContainer {
      display: flex;
      flex-direction: column;
      width: fit-content;
      justify-content: center;
      align-items: center;
      > p {
        text-align: center;
      }
      &__input {
        font-size: 1.25rem;
        width: 20rem;
        height: 3.5rem;
        margin: 0 auto;
        margin-bottom: 1rem;
        text-align: center;
      }
    }
    &__right {
      height: 100vh;
      &__content {
        > h1 {
          font-size: 35px;
        }
      }
    }
    &__link {
      font-size: 0.85rem;
      &--mobile {
        display: flex;
        cursor: pointer;
        overflow-x: hidden;
        position: relative;
        margin-inline: 1rem;
      }
      &--underline {
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.1em;
          background-color: $blue;
          transition:
            opacity 400ms,
            transform 400ms;
          opacity: 1;
          transform: translate3d(-100%, 0, 0);
        }
        &:hover::after,
        &:focus::after {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

.verificationCode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $primary;
  &__logo {
    height: 6.75rem;
    width: 31.8rem;
    margin-bottom: 2.125rem;
  }
  > h2 {
    font-weight: $bold;
    font-size: 1.25rem;
    margin-bottom: 5.625rem;
  }
  > h1 {
    font-size: 50px;
    font-weight: bold;
    font-weight: $medium;
    color: #00bdd5;
    letter-spacing: -2px;
    margin-bottom: 5vh;
  }
  > h3 {
    margin-bottom: 3rem;
  }
  &__p {
    margin-top: 0.5rem;
    font-weight: $medium;
    color: $table-grey;
    font-size: 1.25rem;
    margin-bottom: 2.5rem;
    &__link {
      text-decoration: none;
      font-weight: $bold;
      color: $blueGreen;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 46.56rem;
    > p {
      margin-bottom: 2rem;
    }
    &__input {
      margin: 3rem auto 0 auto;
      display: flex;
      justify-content: center;
      background-color: transparent;
      width: 21.125rem;
      text-align: center;
    }
    > button {
      margin-top: 2rem;
    }
  }
  &__inputContainer2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
  }
  &__link {
    margin-top: 1.375rem;
    font-size: 1.25rem;
    text-decoration: none;
    font-weight: $medium;
    color: $blueGreen;
  }
  &__buttonSend,
  &__buttonVerify {
    width: 20rem;
    height: 3.5rem;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 1024px) {
    height: 100vh;
    &__logo {
      width: 15.8rem;
      height: 3.375rem;
      margin-bottom: 2.125rem;
    }

    > h1 {
      font-weight: $medium;
      font-size: 3rem;
      margin-bottom: 3rem;
    }
    &__p {
      margin-top: 0.937rem;
      font-size: 0.75rem;
      margin-bottom: 2.2rem;
    }
    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__inputContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      &__input {
        width: 20rem;
        height: 3.5rem;
        margin: 1.25rem auto 0 auto;
      }
      > button {
        margin-top: 1.5rem;
      }
    }
    &__inputContainer2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 3rem;
    }
    &__link {
      margin-top: 1rem;
      font-size: 0.75rem;
      align-self: flex-start;
    }
  }
}
