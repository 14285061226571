.cookieModal__btnWrapper {
  justify-self: center;
}

.cookieModal {
  align-items: center;
  background: rgb(255, 255, 255);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 10000000000;
  padding: 1rem;
  bottom: 0px;
}

.cookieModal__link {
  text-decoration: none;
  color: rgb(0, 189, 213);
}

@media only screen and (min-width: 1024px) {
  .cookieModal {
    justify-content: space-between;
    flex-direction: row;
  }

  .cookieModal p {
    font-size: 1rem;
  }
}

.PhoneInput {
  width: 100% !important;
}

input.PhoneInputInput {
  font-size: 1rem !important;
  width: 100% !important;
}
