@import '../../themes/patterns';

.dashboard__accountStatus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  h3 {
    font-weight: 700;
    font-family: $primary;
    font-size: 1rem;
    color: #5e636b;
  }
  &__chipContainer {
    display: flex;
  }
  &__inputContainer {
    display: flex;
    gap: 0.5rem;
    &__button {
      width: unset !important;
      transition: box-shadow 300ms ease-out;
      &:hover {
        box-shadow: $chip-shadow;
      }
    }
    &__input {
      width: unset !important;
    }
  }
  @include xxs {
    &__inputContainer {
      gap: 1rem;
      &__input {
        flex-grow: 1;
        input {
          width: 100%;
        }
      }
    }
  }
}
