* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: $primary;
  font-size: 14px;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
body::-webkit-scrollbar-thumb {
  background-color: $title-grey;
  border-radius: 10px;
}

.strong {
  font-weight: $bold;
}
input[type='file'] {
  display: none;
}

@media screen and (max-width: 1024px) {
  html,
  body {
    font-size: 14px;
  }
}

.gsv {
  position: relative;
  right: 0;
  bottom: 0;
}

details[open] {
  height: auto;
}

details[open] summary ~ * {
  transition: height 3s ease-in;
  animation: sweep 0.5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
