@import '../../themes/patterns';

.spinner {
  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 5rem;
    &__center {
      height: 80vh;
      justify-content: center;
    }
  }
  &__container--noHeight {
    height: unset !important;
    display: contents;
    > div {
      width: 28px !important;
      height: 28px !important;
    }
  }
  &__absolute {
    position: absolute;
    inset: 0 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh !important;
  }
  &__center {
    display: flex;
    justify-content: center;
  }
  &__circle {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: $blue;

    animation: spin 1.2s ease-in-out infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  @media screen and (max-width: 1024px) {
    &__container {
      justify-content: center;
    }
  }
}
