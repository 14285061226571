@import '../../themes/patterns';

.logoBrand {
  align-items: center;
  background-color: $blue;
  cursor: pointer;
  display: flex;
  margin: 0.75rem 0 4rem 0;
  margin-left: 2.5rem;
  &__logo {
    align-items: center;
    background-color: $white;
    border-radius: 10px;
    display: flex;
    height: 3.125rem;
    justify-content: center;
    width: 3.125rem;
    span {
      height: 1.375rem;
    }
  }
  &__brand {
    color: $white;
    font-size: 1.375rem;
    font-weight: $semi-bold;
    margin-left: 1.5rem;
  }
}
