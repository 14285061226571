@import '../../themes/patterns';

.percentBar {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &__textPercent {
    width: 100%;
    margin-top: 1.2rem;
    font-size: 11px;
    font-weight: $bold;
    color: $title-grey;
    opacity: 1;
    display: flex;
    justify-content: flex-start;
  }
  &__percent {
    position: relative;
    width: 100%;
    height: 0.3125rem;
    background-color: #e2e2e2;
    border-radius: 5px 0px 0px 5px;
    &__progress {
      height: 100%;
      background-color: $blue;
      border-radius: 5px 0px 0px 5px;
    }
  }
  &__indicators {
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    margin-top: -0.5rem;
    &__softCap,
    &__hardCap {
      background-color: #e2e2e2;
      height: 10px;
      width: 2.5px;
      position: absolute;
    }
    &__softCap {
      bottom: 0px;
    }
    &__hardCap {
      top: 0px;
      left: 100%;
    }
  }
  &__caps {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: $grey;
    font-weight: $medium;
    &__softCap,
    &__hardCap {
      > span:first-child {
        font-weight: $bold;
      }
      display: flex;
      flex-direction: column;
      font-size: 11px;
    }
    &__hardCap {
      width: 100%;
      margin-top: 0.5rem;
      justify-content: flex-end;
      text-align: right;
    }
    &__softCap {
      position: absolute;
      bottom: 38px;
      color: #6e6e6e;
      font-weight: 500;
      text-align: right;
      width: fit-content;
    }
  }
  @media screen and (max-width: 1024px) {
    &__textPercent {
      > p {
        font-size: 0.625rem;
        margin: 0;
      }
    }
    &__percent {
      width: 100%;
      height: 5px;
    }
    &__caps {
      &__hardCap {
        margin-top: 0.45rem;
      }
    }
  }
}
.percentBar2 {
  &__percentNumber {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
  &__container {
    height: 10px;
    width: 100%;
    background-color: #e2e2e2;
    border-radius: 50px;
    margin-bottom: 3rem;
  }
  &__containerPercent {
    height: 100%;
    background-color: #131788;
    border-radius: inherit;
    border-radius: 50px;
  }
}
