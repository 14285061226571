@import '../../themes/patterns';
@import '../../themes/mixins';

.registration {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  &__popupRegistration {
    height: 90vh !important;
    overflow-y: scroll;
  }
  &__stepper {
    width: 80%;
    display: flex;
    justify-content: flex-end;
  }
  &__popupTerms {
    min-height: 80%;
  }
  &__homelink {
    width: fit-content;
    p {
      font-size: 1.1rem;
    }
    @include xxs {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      justify-content: center;
    }
  }
  &__container {
    width: 80%;
    display: flex;
    flex-direction: column;
    &__one {
      width: 100%;
      margin-right: 2rem;
      &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 1rem;
        width: 100%;
      }
    }
    &__two {
      min-height: 70vh;
      display: flex;
      align-items: flex-end;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    &__popupRegistration {
      height: 90vh !important;
      overflow-y: scroll;
      overflow-x: visible;
      padding: 1rem;
    }
    &__container {
      padding: 0rem 1rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      &__one {
        width: 100%;
        margin-right: 0;
        &__buttons {
          width: 100%;
          display: flex;
          flex-direction: column-reverse;
        }
      }
      &__two {
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
      }
    }
  }
}

.personalDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  > form > div:first-child {
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  h1 {
    grid-column: 1/3;
    font-size: 1.5rem;
    font-weight: $bold;
    margin-bottom: 2rem;
    @include xxs {
      margin-bottom: unset !important;
    }
  }
  h2 {
    font-size: 1.25rem;
    letter-spacing: -0.74px;
    font-weight: $regular;
    margin-top: 1.5rem;
  }
  &__inputGrid {
    display: grid;
    grid-template-columns: 0.2fr 2fr;
    column-gap: 0.923rem;
    margin-top: 0.5rem;
    &__phone {
      width: 210px;
    }
    &__code {
      width: 80px;
      margin-bottom: -10px;
    }
  }
  &__checkbox {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 2rem;
    padding-right: 1.1rem;
    p {
      font-size: 1rem;
    }
    a {
      font-weight: $bold;
      color: $blue;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1024px) {
    margin: 1rem auto 0 auto;
    > h1 {
      font-size: 1.38rem;
      margin-top: 0rem;
      margin-bottom: 2.75rem;
      text-align: center;
      letter-spacing: -0.82px;
      color: $title-grey;
    }
    > h2 {
      font-size: 0.825rem;
      letter-spacing: -0.59px;
      font-weight: $regular;
    }
    &__inputGrid {
      grid-template-columns: 0.5fr 1fr;
      column-gap: 0.3125rem;
    }
    &__checkbox {
      grid-column: 1/3;
      width: 100%;
      height: 3rem;
      font-size: 0.75rem;
      font-weight: $semi-bold;
      display: flex;
      justify-content: center;
      &__link {
        color: $blue;
        cursor: pointer;
      }
    }
  }
}

.firstFork {
  width: 100%;
  min-height: 60vh;
  > h1 {
    font-size: 1.5rem;
    font-weight: $bold;
    margin-bottom: 2rem;
  }
  > h2 {
    font-size: 1.25rem;
    letter-spacing: -0.74px;
    font-weight: $regular;
    margin-bottom: 3rem;
  }
  &__buttons {
    display: flex;
    width: 30rem;
    justify-content: space-between;
  }
  &__button {
    position: relative;
    &__tooltip {
      position: absolute;
      top: 220px;
      left: -0px;
      display: none;
      text-transform: none;
    }
    &:hover {
      .firstFork__button__tooltip {
        display: flex;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    > h1 {
      font-size: 1.38rem;
      margin-top: 0rem;
      margin-bottom: 2rem;
      text-align: center;
      letter-spacing: -0.82px;
      color: $title-grey;
    }
    > h2 {
      font-size: 1rem;
      letter-spacing: 0px;
      font-weight: $regular;
    }
    &__buttons {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
    &__button {
      &__tooltip {
        top: 190px;
        left: -15px;
      }
    }
  }
}

.companyDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5rem;
  min-height: 80vh;
  > h1 {
    font-size: 1.5rem;
    font-weight: $bold;
    margin: 2rem 0;
  }
  &__subtitle {
    font-size: 1.25rem;
    letter-spacing: -0.74px;
    font-weight: $regular;
    margin-bottom: 2rem;
  }
  > h2 {
    font-size: 1.25rem;
    letter-spacing: -0.74px;
    font-weight: $regular;
    margin-top: 1.5rem;
  }
  &__inputGrid {
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    column-gap: 0.923rem;
    &__phone {
      width: 210px;
    }
    &__code {
      width: 80px;
      margin-bottom: -10px;
    }
  }
  &__addField {
    grid-column: 1/3;
    @extend .companyDetails;
    height: auto;
    min-height: auto;
    margin-bottom: 0;
  }
  &__checkbox {
    margin: 2rem 0;
    height: 3rem;
    display: flex;
    align-items: center;
  }
  &__skipcontainer {
    grid-column: 3/3;
    width: 100%;
    text-align: end;
    &__skip {
      font-family: $primary;
      text-decoration: none;
      font-weight: $medium;
      font-size: 1.25rem;
      color: $blue;
      margin-left: auto;
      margin-bottom: 2rem;
      margin-right: 1rem;
    }
  }
  &__email {
    display: flex;
    flex-direction: row;
    position: relative;
    .inputHover {
      width: 310px;
    }
    input {
      margin-right: 1rem;
    }
    button {
      height: 3rem;
      display: flex;
      justify-content: flex-start;
      margin-left: 2rem;
      margin-bottom: 7rem;
      width: 18rem;
      padding: 1rem 0rem;
      z-index: 101;
      &:last-child {
        margin-bottom: 0rem;
      }
    }
    button > span,
    &__buttons button > span {
      min-width: max-content;
      color: $blue;
      font-weight: $semi-bold;
      font-size: 13px;
      text-transform: none !important;
    }
    &__buttonsMobile {
      display: none;
    }
    &__buttons {
      width: min-content;
      position: absolute;
      left: 390px;
      top: -5px;
    }
    &__button {
      svg {
        background: linear-gradient(90deg, #1c7391 0%, #00bdd5 100%);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.24);
        border-radius: 4px;
        padding: 5px;
        width: 30px;
        height: 30px;
        margin-right: 5px;
        stroke: white;
      }
      &--disabled {
        @extend .companyDetails__email__button;
        svg {
          background: $table-grey;
        }
        span {
          color: $table-grey !important;
        }
      }
    }
  }
  .MuiButton-root {
    text-transform: none;
    font-weight: $regular;
    font-family: $secondary;
    font-size: 0.9rem;
    border-radius: 10px;
  }
  @media screen and (max-width: 1024px) {
    > h1 {
      font-size: 1.38rem;
      margin-top: 0rem;
      margin-bottom: 0;
      text-align: center;
      letter-spacing: -0.82px;
      color: $title-grey;
      grid-column: 1/3;
    }
    > h2 {
      font-size: 1rem;
      letter-spacing: 0px;
      font-weight: $regular;
      margin-bottom: 0;
    }
    &__subtitle {
      grid-column: 1/3;
      font-size: 1rem;
      letter-spacing: 0px;
      font-weight: $regular;
      margin-bottom: 1.9375rem !important;
    }
    &__inputGrid {
      grid-template-columns: 0.5fr 1fr;
      column-gap: 0.3125rem;
    }
    &__checkbox {
      grid-column: 1/3;
      height: 3rem;
      margin-bottom: 0;
      font-size: 0.75rem;
      display: flex;
      justify-content: center;
    }
    &__skipcontainer {
      &__skip {
        font-size: 0.8125rem;
        grid-column: 1/3;
      }
    }
    &__email {
      display: flex;
      flex-direction: column;
      input {
        margin-right: 1rem;
      }
      button > span {
        margin-top: 1rem;
        padding: 0rem;
        height: 3rem;
        justify-content: flex-start;
        font-size: 10px;
      }
      button {
        width: 100%;
        margin-bottom: 0rem;
      }
      &__buttonsMobile {
        margin-top: 2rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        left: auto;
        top: auto;
      }
      &__button {
        width: 100%;
        height: 3.5rem;
        padding: 0 1rem !important;
        background: linear-gradient(90deg, #1c7391 0%, #00bdd5 100%);
        color: white;
        > span {
          color: white;
        }
        &.MuiButton-root.Mui-disabled {
          background: $table-grey !important;
          > span {
            color: white !important;
          }
        }
      }
      &__buttons {
        display: none;
      }
    }
  }
}

.verifyYourIdentity {
  width: 100%;
  height: 100%;
  &__skip {
    font-family: $primary;
    text-decoration: none;
    align-self: flex-end;
    font-weight: $medium;
    font-size: 1.25rem;
    color: $blue;
  }
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h1 {
      font-size: 1.5rem;
      font-weight: $bold;
      margin: 2rem 0;
    }
    h2 {
      font-size: 1.25rem;
      letter-spacing: -0.74px;
      font-weight: $regular;
      margin-bottom: 3rem;
    }
    &__p {
      font-weight: $regular;
      font-size: 1.25rem;
      letter-spacing: -0.74px;
      margin-bottom: 2rem;
    }
    &__one {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__two {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__card {
      width: 100%;
      height: 8.3125rem;
      &__p {
        font-size: 1rem;
        font-weight: $medium;
        color: $blue;
        margin-top: 0.2rem;
        text-align: left;
      }
    }
    &__radio {
      display: flex;
      flex-direction: column;
      margin-left: 3rem;
    }
    &__errorRadio {
      margin-top: 1rem;
      margin-top: 0.5rem;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    height: 3rem;
    &__label {
      display: flex;
      align-items: center;
    }
    &__link {
      font-weight: $semi-bold;
      color: $blue;
      cursor: pointer;
    }
  }
  &__upload {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $blue;
    font-size: 1.15rem;
    font-weight: bold;
  }
  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    &__checkbox {
      width: 100%;
      font-size: 0.8rem;
      font-weight: $semi-bold;
      display: flex;
      justify-content: flex-start;
      margin-left: 0rem;
      &__link {
        font-size: 1rem;
        align-self: center;
      }
    }
    &__skip {
      font-size: 0.8125rem;
    }
    &__form {
      grid-template-columns: 100%;
      grid-row-gap: 0;
      h1 {
        font-size: 1.38rem;
        margin-top: 0rem;
        margin-bottom: 2.75rem;
        letter-spacing: -0.82px;
        color: $title-grey;
      }
      h2 {
        font-size: 1rem;
        letter-spacing: 0px;
        font-weight: $regular;
        margin-bottom: 1.5rem;
      }
      &__p {
        font-weight: $medium;
        font-size: 0.8125rem;
        margin-bottom: 1rem;
      }
      &__card {
        height: 7rem;
        &__p {
          margin-top: 0.5rem;
          font-weight: $medium;
          font-size: 0.72rem;
        }
      }
      &__radio {
        margin-left: 0;
      }
    }
  }
}

.registrationCompleted {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  > h1 {
    font-size: 1.5rem;
    font-weight: $bold;
    margin: 2rem 0;
  }
  > h2 {
    font-size: 1.25rem;
    letter-spacing: -0.74px;
    font-weight: $regular;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 10rem;
    > h1 {
      font-size: 1.38rem;
      margin-top: 0rem;
      margin-bottom: 2.75rem;
      text-align: center;
      letter-spacing: -0.82px;
      color: $title-grey;
    }
    > h2 {
      font-size: 1rem;
      letter-spacing: 0px;
      font-weight: $regular;
      margin-bottom: 0;
    }
  }
}

.button__next {
  width: 11.25rem;
  margin-top: 2.2rem;
  text-decoration: none;
  @media screen and (max-width: 1024px) {
    width: 19.8rem;
  }
}
.button__container {
  display: flex;
  justify-content: center;
  &__back,
  &__next {
    @extend .button__next;
    margin: 4rem 0.5rem;
  }
  @media screen and (max-width: 1024px) {
    &__back,
    &__next {
      margin: 2.2rem 0.5rem;
    }
  }
}
.button__home {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  &__btn {
    @extend .button__next;
    margin-top: 12rem;
  }
  @media screen and (max-width: 1024px) {
    justify-content: center;
    &__btn {
      margin-top: 2.2rem;
    }
  }
}

.error__form {
  select,
  input,
  textarea {
    border-bottom: 2px solid $red !important;
  }
  select {
    padding-bottom: 0.17rem;
  }
  input {
    margin-bottom: 0rem;
  }
  textarea {
    margin-bottom: 0.1rem;
  }
  @media screen and (max-width: 1024px) {
    &__currency {
      margin-bottom: 2rem;
    }
  }
}

.error__text {
  font-size: 13px !important;
  margin-left: 0 !important;
  width: 310px;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  text-align: left;
  color: $red;
  &__left {
    @extend .error__text;
    text-align: left;
  }
  &__termsAndConditions {
    @extend .error__text;
    width: auto;
    padding-left: 8px;
  }
}

.text-blue {
  > div {
    > h1 {
      color: $blueGreen;
    }
  }
}
