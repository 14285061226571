@import '../../themes/patterns';

.checkboxCircleMain {
  // width: auto;
  // height: 100%;
  // font-family: $primary;
  // font-weight: $semi-bold;
  // font-size: 1.25rem;
  // cursor: pointer;

  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &__checkbox {
    // margin-right: 1rem;
    // width: 1rem;
    // height: 1rem;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkboxCircleMain__checkmark {
      background-color: #2196f3;
    }
    &:checked ~ .checkboxCircleMain__checkmark:after {
      display: block;
    }
  }
  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;

    border-radius: 15px;
    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    // width: 100%;
    // height: 100%;
    // font-family: $primary;
    // font-weight: $semi-bold;
    // font-size: 1.25rem;
    // outline: none;
    // cursor: pointer;
    // &__checkbox{
    //     margin-right: 1rem;
    //     width: 1rem;
    //     height: 1rem;
    // }
  }
}

.checkboxCircleMain .checkboxCircleMain__checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
